import { Fragment } from 'react';
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import './loader.css';
import Load from './loader.gif';
const Loader = (props:any) =>{
    const onHide = () => {
        props.setLoader(false);
    }

    return <Fragment>
        <div className="Loader-screen">
        <Dialog visible={props.visible} style={{ width: '358px', height:'150px' }} onHide={() => onHide()}>
            <div className="loader-inner">

            {/* <span><ProgressSpinner/></span><span className="loadingTxt"> Loading...</span> */}

            <span ><img src={Load} alt="spinner" /></span> <span className="loadingTxt"> Loading</span>
           
            </div>
        
        </Dialog>
        </div>
    </Fragment>
}

export default Loader;