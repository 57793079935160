import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from "react-router";
import './SubscriptionPlans.css'
import Loader from '../components/Loader/loader';
import { useStore } from '../stores/useStore';
const MySubscriptions = () => {
    const history = useHistory();
    const { restaurantStore, userSessionStore } = useStore();
    const [loader, setLoader] = useState(false);

   
    const [plans, setPlans] = useState([]);
    useEffect(() => {
        setLoader(true)
        restaurantStore.planDetails()
            .then(([success, message]: any) => {
                if (success) {
                    let plan = message
                    setPlans(plan)
                    setLoader(false)
                    console.log(message)

                } else {
                    console.log(message)
                }
            })
    }, [])

   
    return <div className="subscriptions">
            <div><Loader setLoader={setLoader} visible={loader} /></div> 
        <div className="breadcrum">
            <h2 onClick={() => history.goBack()} className="breadcrumText activeBreadcrum">SUBSCRIPTIONS</h2 >
            <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">SUBSCRIPTION PLANS</h2>
        </div>
        <hr className="hr" />
        {/* <h1 className="msHeading">SUBSCRIPTION PLANS</h1> */}
        <div className="subscriptions-body">

        {plans?.map((plan: any) => (

            <div className="currentSe">

                <div className="planCards">
                    <h6 className="planBadge">{plan.product_name}</h6>
                    <h3 className="planPrice">£ {(plan.product_price) / 100}</h3>
                    {/* <h5 className="planType">Per {}</h5> */}
                </div>

                <h1 className="msHeading">PLAN FEATURES</h1>
                <hr className="hr" />
                <div className="planList">
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Automatic table assignment</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>{plan.product_name ==="Free Plan"?"Up To 100":"Unlimited"} Bookings/Month</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>SMS and Email notifications</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Push Notifications</h3>
                    <h3 className="planDetails"><i className="pi pi-check checkList"></i>Manual Table Assignment</h3>
                </div>
            </div>
                ))}
       </div>
    </div>
}
export default MySubscriptions