// eslint-disable-next-line 
import React, { Component } from 'react';
import { useStore } from './stores/useStore';
import AccessDenied from './pages/AccessDenied'

// to be used with class components only. for functional componets, use useStore hook directly
export const userSessionStoreHoc = (Component: any) => {
    return (props: any) => {
        const { userSessionStore } = useStore();
        return <Component userSession={userSessionStore} {...props} />
    }
}

export const accessControlHoc = (Component: any) => {
    return (props: any) => {
        const { userSessionStore } = useStore();
        const userRoles = userSessionStore.user // temp fix. Actual user roles should come from user object
        let allow = userRoles.includes("admin"||"coadmin")
        return (
            allow ? <Component /> : <AccessDenied />
        )
    }
}