import { observer } from "mobx-react-lite";
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { Fragment, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { useStore } from "../stores/useStore";
import { Calendar } from 'primereact/calendar';
import Loader from '../components/Loader/loader';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import './renderlist.css'
import { createConstructor } from "typescript";
import { SSL_OP_NETSCAPE_REUSE_CIPHER_CHANGE_BUG } from "constants";

export const DemoRequests: React.FC<any> = observer(() => {
    const { restaurantStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [globalFilter, setGlobalFilter] = useState("")
    const [dataRes, setData] = useState()
    const [refresh, setRefresh] = useState(false)
    const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
    const [loader, setLoader] = useState(false);
    const [dialog, setDialog] = useState(false)
    const [dialog2, setDialog2] = useState(false)

    const pageSize = 10;

    const duration = [
        { name: 'Pending', code: 'Pending' },
        { name: 'Cancelled', code: "Cancelled" },
        { name: 'Completed', code: "Completed" },
    ];
    const [selectedDuration, setSelectedDuration] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<any>(duration[0]);
    const [notesd, setNotesd] = useState<any>();
    

    const onHide = () => {
        setDialog(false);
        setDialog2(false);
        setSelectedDuration('');
        setNotesd('');
    }


    const onSetSearch=(e:any)=>{
        setSelectedStatus(e.value)
        getReservations(0,e.value.name)
        setCount(0)
    }
    const setDuration = (e: any) => {
        setSelectedDuration(e.value);
        console.log(e.value)

    }
    const demoUpdate = (id:any,status:any,note:any) => {
        setLoader(true)
        let data = {
            "id": id,
            "status":status,
            "note": note
        }
        restaurantStore.updateDemoStatus(data)
        .then(([success, data]) => {
            if (success) {
                console.log("demo update data", data);
                setCount(0)
                getReservations(0,'');
                setSelectedStatus(duration[0])
                setLoader(false)
                onHide();
            } else {
                setErrorMsg(data.msg)
                setSelectedStatus(duration[0])
                setLoader(false);
                setCount(0)
                getReservations(0,'')
            }
        })
        console.log(data)
    }

    const [count, setCount] = useState(0);

    
    const onLeftClick = () => {
        

        if (count > 0) {
            setCount(count - 1)
            setLoader(true);
            getReservations(count - 1,selectedStatus.name)

        }
        else {
            setCount(0)
        }

        console.log("left clicked", count);
    }

    const onRightClick = () => {

        console.log("clicked ");
        console.log("count ",count);

  if (count >= 0 && count < Math.ceil((dataRes?.total) / 10) - 1) {
            setCount(count + 1)
            setLoader(true);
            getReservations(count + 1,selectedStatus.name)
        }

        console.log("Right clicked", count);

    }


    const onSearch = (e: any) => {
        console.log("search", e.target.value)
        setGlobalFilter(e.target.value)
        if (e.target.value !== "") {
            getReservations(0,  (e.target.value).replace("+", "%2B"))
        }
        else {
            console.log("no search phrase")
            getReservations(0)
        }


    }




    useEffect(() => {
        setLoading(true)
        setLoader(true)
        const page = 0;
        setCount(0)
                const pageSize = 10;
        getReservations(0)
    }, [refresh])


    const getReservations = (page: any, searchTxt?: any,) => {
        // let selectedDate = date2;
        // if (searchDate) {
        //     selectedDate = searchDate;
        // }
        console.log("Filter data : ", searchTxt, page)
        restaurantStore.getDemoRequestList( searchTxt ? searchTxt : "", page)
            .then(([success, data]) => {
                if (success) {
                    setData(data);
                    console.log("demo request data", data?.items);
                    setLoader(false);
                } else {
                    setErrorMsg(data.msg)
                    setLoader(false);
                }
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }
    const [status, setStatus] = useState();
    const [demoId, setDemoID] = useState();

    const schedule = (tableData: any) => {
        return tableData.demo_date +" "+ tableData.demo_time
    }




    const demoStatus = (tableData: any) => {
        return <p style={{textDecoration:'underline'}} onClick={() => {
            setStatus(tableData)
            setDemoID(tableData.demo_request_id)
            setDialog2(true);
        }}>Update</p>
    }
    const [note, setNote] = useState();
    const notes = (tableData: any) => {
        return <p onClick={() => {
            setNote(tableData.note)
            setDialog(true);
        }} style={{ color: "blueviolet", fontSize: "12px",cursor:"pointer" }}>See Notes</p>
    }


    // const pageCount=data?Math.ceil(data.length/pageSize):0;

    // if(pageCount === 1) return null;

    // const pages= range(1,pageCount+1)


    const Mindate = () => {
        let today = moment().subtract(1, 'years').calendar();
        return new Date(today)
    }
    const Maxdate = () => {
        let today = moment().add(1, 'months').calendar();
        return new Date(today)
    }
    const calsearch = (e: any) => {
        let date = moment(e.target.value).format("YYYY-MM-DD").toString();
        setDate2(date)
        getReservations(0, date)
        // console.log("date check",e.target.value);
    }
    const globalSearch = () => {
        return (
            <div className="calHead">
                <div className="searchHold">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText autoComplete="off" className="SearchBar" maxLength={20} style={{ width: "95%" }} type="search" onChange={(e: any) => onSearch(e)}
                        placeholder="Search" />
                </div>
                <div className="dashboard-admin">
                            <Dropdown style={{ fontSize: '20px' }} onChange={onSetSearch} value={selectedStatus} options={duration} optionLabel="name" placeholder="Pending" />
                        </div>
                {/* <button className="calSearch"> Search</button> */}

                {/* <Calendar readOnlyInput dateFormat="dd/mm/yy" minDate={Mindate()} maxDate={Maxdate()} placeholder="Search By Date" style={{ width: '300px', height: '45px' }} id="icon" value={new Date(date2)} onChange={(e: any) => calsearch(e)} showIcon /> */}
            </div>

        );
    }



    return (
        <Fragment>
            <div className="breadcrum">
                <h2 className="breadcrumText">DEMO REQUESTS</h2 >
            </div>
            <hr className="hr" />

            {loading ? <div><Loader setLoader={setLoader} visible={loader} /></div> : errorMsg ? <div className='error-msg'>{errorMsg}</div> : <div className="p-grid p-fluid">
                <div><Loader setLoader={setLoader} visible={loader} /></div>
                <div className="p-col-12 resList">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className="p-col-12">
                                {/* {(dataRes?.items).length === 0 ? <div className="nodata"><h1>No Reservation Data Available</h1><img src="assets/images/nodata.png" alt="nodata" /> </div> : */}
                                <DataTable value={dataRes?.items} header={globalSearch()} 
                                    responsive={true} rows={10} 
                                    emptyMessage="No Demo Requests Found" resizableColumns={true} columnResizeMode="expand">
                                    <Column field="customer_name" header="Customer Name" style={{ width: '190px' }} />

                                    {/* <Column field='booked_date' body={(rowData: any) => moment(rowData['booked_date']).format('DD/MM/YYYY').toString()} header="Date"
                                        style={{ width: '125px' }} /> */}
                                    <Column field="customer_email" header="Email ID" style={{ width: '300px' }} />
                                    <Column field="customer_mobile" header="Phone" style={{ width: '150px' }} />
                                    {/* <Column field="registered_business_name" header="Organization" style={{ width: '190px' }} /> */}
                                    <Column field="restaurant_name" header="Restaurant Name" style={{ width: '190px' }} />
                                    <Column body={schedule} header="Schedule" style={{ width: '175px' }} />
                                    {/* <Column field="demo_date" header="Booked Date" style={{ width: '110px' }} /> */}
                                    <Column body={demoStatus} header="Status" style={{ width: '100px',color:"#0052C3"}} />
                                    <Column body={notes} header="Notes" style={{ width: '120px' }} />
                                    {/* <Column body={deleteTemplate} header="Action" style={{ width: '80px', color: '#ED5200 !important' }} /> */}
                                </DataTable>
                                {/* } */}

                                <br />
                                <div style={{ textAlign: 'center', columnGap: '10px' }}>
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", marginRight: "10px", cursor: "pointer" }} className="fas fa-chevron-left" onClick={onLeftClick}></i>
                                    Page: {count + 1} of {dataRes.total>10?Math.ceil(dataRes?.total/10):1}
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", cursor: "pointer", marginLeft: "10px" }} className="fas fa-chevron-right" onClick={onRightClick}></i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
                <div className="notes">
                    <i onClick={onHide} className="pi pi-times close"></i>
                    <br />
                    <i className="fas fa-quote-left"></i>
                    <p>{note === null ? "No notes for this Demo Request" : note}</p>
                    <i style={{ float: "right" }} className="fas fa-quote-right"></i>
                </div>
            </Dialog>
            <Dialog visible={dialog2} style={{ width: '358px' }} onHide={() => onHide()}>
                <div className="notes">
                    <i onClick={onHide} className="pi pi-times close"></i>
                    <br />
                    <form onSubmit={(e: any) => { e.preventDefault();demoUpdate(demoId,(selectedDuration?.name || status?.status),notesd); }}>
                        <h3>Change Status</h3>
                        <br />
                        <h6>Status</h6>
                        <div className="dashboard-admin">
                            <Dropdown style={{ fontSize: '20px' }} onChange={setDuration} value={selectedDuration} options={duration} optionLabel="name" placeholder={status?.status} />
                        </div>

                        {/* <p>{status?.status}</p> */}
                        <br />
                        <h6>Notes</h6>
                        <textarea value={notesd} onChange={(e: any) => { setNotesd(e.target.value) }}></textarea>
                        <br />
                        <br />
                        <button type="submit" className="filled">UPDATE</button>
                        <button onClick={(e: any) => { e.preventDefault(); onHide() }} className="outline">CANCEL</button>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    );
})