import React, { Fragment } from "react";
import "./thankyou.css";

const Thankyou = () => {

    return <Fragment>
        <div className="thankYou">
            <div className="thankYou-card">
                {/* <i class="far fa-check-circle cancel-close" style={{marginLeft:'10px'}}></i> */}
                <img src='./assets/images/right.png' alt="success" />
                <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>Thank You!</h4>
                <p style={{ textAlign: "center" }}>You have successfully upgraded to Professional Plan</p>
            </div>
        </div>
    </Fragment>
}

export default Thankyou
