import React, { useState } from 'react';
import { useStore } from '../stores/useStore';
import { useHistory } from 'react-router';
import { Dialog } from 'primereact/dialog';

import { Link } from 'react-router-dom';

const SideNav = () => {
    const { userSessionStore } = useStore();
    const history = useHistory();
    const [dialog, setDialog] = useState(false)

    const onclick = () => {
        setDialog(true);
    }

    const onHide = () => {
        setDialog(false);
    }


    const floor = <svg xmlns="http://www.w3.org/2000/svg"  width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="clip-Artboard_2">
        <rect width="26" height="26"/>
      </clipPath>
    </defs>
    <g id="Artboard_2" data-name="Artboard – 2" clipPath="url(#clip-Artboard_2)">
      <g id="Group_37818" data-name="Group 37818" transform="translate(-7 -7)">
        <g id="Group_37817" data-name="Group 37817" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2492" data-name="Rectangle 2492" width="6" height="6" rx="1" transform="translate(104 487)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2493" data-name="Rectangle 2493" width="6" height="6" rx="1" transform="translate(112 487)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2494" data-name="Rectangle 2494" width="6" height="6" rx="1" transform="translate(120 487)" fill="#510aea"/>
        </g>
        <g id="Group_37816" data-name="Group 37816" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2497" data-name="Rectangle 2497" width="6" height="6" rx="1" transform="translate(104 495)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2496" data-name="Rectangle 2496" width="6" height="6" rx="1" transform="translate(112 495)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2495" data-name="Rectangle 2495" width="6" height="6" rx="1" transform="translate(120 495)" fill="#510aea"/>
        </g>
        <g id="Group_37815" data-name="Group 37815" transform="translate(-95 -478)">
          <rect className="rect-floor" id="Rectangle_2500" data-name="Rectangle 2500" width="6" height="6" rx="1" transform="translate(104 503)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2499" data-name="Rectangle 2499" width="6" height="6" rx="1" transform="translate(112 503)" fill="#510aea"/>
          <rect className="rect-floor" id="Rectangle_2498" data-name="Rectangle 2498" width="6" height="6" rx="1" transform="translate(120 503)" fill="#510aea"/>
        </g>
      </g>
    </g>
  </svg>
  
    return <div className="side-nav left-menu text-center bg-white index-8"><ul>
        
        <li>
            <Link  to="/" className="nav-div">
            <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                {/* <i className="pi pi-home "> </i> */}
                <svg className="svgIcon" xmlns="http://www.w3.org/2000/svg" width="17.893" height="19.659" viewBox="0 0 17.893 19.659"><defs></defs><g transform="translate(1 1)"><path className="a" d="M4.5,9.181,12.446,3l7.946,6.181v9.712a1.766,1.766,0,0,1-1.766,1.766H6.266A1.766,1.766,0,0,1,4.5,18.893Z" transform="translate(-4.5 -3)"/><path className="a" d="M13.5,26.829V18h5.3v8.829" transform="translate(-8.202 -9.171)"/></g></svg>
            </figure>
            <span className=" d-block">Dashboard</span>
        </Link>        
        </li>
        <li>
            <Link to='/restaurantlist' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi  pi-ticket "> </i> */}
                    <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="19.313" height="30.901" viewBox="0 0 19.313 30.901">
  <path id="Icon_map-restaurant" data-name="Icon map-restaurant" d="M14.925,1.32V8.445H13.638V1.364a.644.644,0,1,0-1.288,0V8.445H11.063v-7.1a.645.645,0,0,0-1.288-.021V8.445H8.488V1.37A.644.644,0,0,0,7.2,1.335v9.317a2.429,2.429,0,0,0,2.575,2.229V29.689a1.931,1.931,0,0,0,3.863,0V12.882A2.577,2.577,0,0,0,16.213,10.8V1.341A.646.646,0,0,0,14.925,1.32ZM20.719,3.3V19.389h1.288v10.3c0,2.575,4.506,2.575,4.506,0V3.3C26.513.076,20.719.076,20.719,3.3Z" transform="translate(-7.2 -0.72)"/>
</svg>                </figure>
                <span className=" d-block">Restaurants</span>
            </Link>
        </li>
        <li>
            {/* <Link to='/create-floor-plan' className="nav-div">
            <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                <i className="pi  pi-plus "> </i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24.627" height="17.591" viewBox="0 0 24.627 17.591">
  <g id="Icon_ionic-ios-card" data-name="Icon ionic-ios-card" transform="translate(-2.25 -6.75)">
    <path id="Path_42388" data-name="Path 42388" d="M26.877,8.509A1.764,1.764,0,0,0,25.118,6.75H4.009A1.764,1.764,0,0,0,2.25,8.509v2.639H26.877Z" fill="#510aea"/>
    <path id="Path_42389" data-name="Path 42389" d="M2.25,24.545A1.764,1.764,0,0,0,4.009,26.3H25.118a1.764,1.764,0,0,0,1.759-1.759v-8.8H2.25Zm7.806-2.639h9.015a.772.772,0,0,1,.77.77h0a.772.772,0,0,1-.77.77H10.056a.772.772,0,0,1-.77-.77h0A.772.772,0,0,1,10.056,21.907Zm-3.958,0h.66a.772.772,0,0,1,.77.77h0a.772.772,0,0,1-.77.77H6.1a.772.772,0,0,1-.77-.77h0A.772.772,0,0,1,6.1,21.907Z" transform="translate(0 -1.964)" fill="#510aea"/>
  </g>
</svg>

                {floor}
            </figure>
            <span className=" d-block">Subscriptions</span>
        </Link> */}
        </li>
        <li>
            <Link to='/demorequests' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                {/* <i class="far fa-bookmark"></i> */}
                {/* <img className="svgIcon" src="./assets/images/bookmark.svg" alt="" /> */}
                <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="23.247" height="18.598" viewBox="0 0 23.247 18.598">
  <path id="Icon_material-mail" data-name="Icon material-mail" d="M23.922,6H5.325A2.322,2.322,0,0,0,3.012,8.325L3,22.273A2.332,2.332,0,0,0,5.325,24.6h18.6a2.332,2.332,0,0,0,2.325-2.325V8.325A2.332,2.332,0,0,0,23.922,6Zm0,4.649-9.3,5.812-9.3-5.812V8.325l9.3,5.812,9.3-5.812Z" transform="translate(-3 -6)" />
</svg>                </figure>
                <span className=" d-block">Demo Requests</span>
            </Link>
        </li>
        <li>
            <Link to='/subscriptions' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi pi-cog "> </i> */}
                    <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="24.627" height="17.591" viewBox="0 0 24.627 17.591">
  <g id="Icon_ionic-ios-card" data-name="Icon ionic-ios-card" transform="translate(-2.25 -6.75)">
    <path id="Path_42388" data-name="Path 42388" d="M26.877,8.509A1.764,1.764,0,0,0,25.118,6.75H4.009A1.764,1.764,0,0,0,2.25,8.509v2.639H26.877Z"/>
    <path id="Path_42389" data-name="Path 42389" d="M2.25,24.545A1.764,1.764,0,0,0,4.009,26.3H25.118a1.764,1.764,0,0,0,1.759-1.759v-8.8H2.25Zm7.806-2.639h9.015a.772.772,0,0,1,.77.77h0a.772.772,0,0,1-.77.77H10.056a.772.772,0,0,1-.77-.77h0A.772.772,0,0,1,10.056,21.907Zm-3.958,0h.66a.772.772,0,0,1,.77.77h0a.772.772,0,0,1-.77.77H6.1a.772.772,0,0,1-.77-.77h0A.772.772,0,0,1,6.1,21.907Z" transform="translate(0 -1.964)" />
  </g>
</svg>                </figure>
                <span className=" d-block">Subscriptions</span>
            </Link>
        </li>
        {/* <li>
            <Link to='/profile-settings' className="nav-div">
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    <i className="pi pi-fw pi-cog "> </i>
                </figure>
                <span className=" d-block">Settings</span>
            </Link>
        </li> */}
    
        {/* <Link to='/restaurantlist' className="nav-div">
            <a href="/restlist" className="pi pi-fw pi-cog "> </a><br />
            <span className="">Settings</span>
        </Link> */}
        <li onClick={onclick} className="nav-div">
            <a>
                <figure className="icon d-flex align-items-center justify-content-center flex-no-wrap transition">
                    {/* <i className="pi pi-fw pi-sign-out"></i> */}
                    <svg className="svgIcon2 " xmlns="http://www.w3.org/2000/svg" width="20.146" height="20.146" viewBox="0 0 20.146 20.146"><defs></defs><path className="a logout" d="M12.447,18.6l1.567,1.567,5.6-5.6-5.6-5.6-1.567,1.567,2.91,2.91H4.5v2.238H15.3ZM22.408,4.5H6.738A2.245,2.245,0,0,0,4.5,6.738v4.477H6.738V6.739h15.67v15.67H6.738V17.931H4.5v4.477a2.245,2.245,0,0,0,2.238,2.238h15.67a2.245,2.245,0,0,0,2.238-2.238V6.738A2.245,2.245,0,0,0,22.408,4.5Z" transform="translate(-4.5 -4.5)"/></svg>
                </figure>
                <span className="d-block">Log Out</span>
            </a>
        </li>
        </ul>

        <Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
            <h3 className="logoutTtl">Are you sure, you want to log out?</h3>
            <br/>
            <span><button onClick={onHide} className="outline">No</button></span>

            <span><button onClick={(e) => { userSessionStore.logout(); history.replace('/logoutConformation'); onHide(); }} className="filled">Yes</button></span>
            <br/>

        </Dialog>
    </div>

}

export default SideNav




// (e) => { userSessionStore.logout(); history.replace('/login') }