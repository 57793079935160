import React from 'react'
import { UserSessionStore}  from './UserSessionStore'
import { RestaurantStore } from './RestaurantStore'
// import { UserSessionStore2}  from './UserSessionStore2'


function createRootStore(){
    const userSessionStore = new UserSessionStore()
    // const userSessionStore2 = new UserSessionStore2()
    const restaurantStore = new RestaurantStore()

    return {
        userSessionStore: userSessionStore,
        // userSessionStore2: userSessionStore2,
        restaurantStore: restaurantStore
    }
}

const rootStore = createRootStore()

const storeContext = React.createContext(rootStore)

export const useStore = () => React.useContext(storeContext)