import { observer } from "mobx-react-lite";
import moment from 'moment';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { Fragment, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { useStore } from "../stores/useStore";
import { Calendar } from 'primereact/calendar';
import Loader from '../components/Loader/loader';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router";
import './renderlist.css'
import './CancellationRequests.css'

export const CancellationRequests: React.FC<any> = observer(() => {
    const history = useHistory();
    const { restaurantStore } = useStore()
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState(null)
    const [globalFilter, setGlobalFilter] = useState("")
    const [dataRes, setData] = useState()
    const [refresh, setRefresh] = useState(false)
    const [date2, setDate2] = useState(moment().format("YYYY-MM-DD"));
    const [loader, setLoader] = useState(false);
    const [dialog, setDialog] = useState(false)
    const [dialog2, setDialog2] = useState(false)
    const [dialog3, setDialog3] = useState(false)

    const pageSize = 10;
    const duration = [
        { name: 'Pending', code: 'Pending' },
        { name: 'Rejected', code: "Rejected" },
        { name: 'Completed', code: "Accepted" },
    ];
    const [selectedDuration, setSelectedDuration] = useState<any>(duration[0]);
    const [notesd, setNotesd] = useState<any>();
    

    const onHide = () => {
        setDialog(false);
        setDialog2(false);
        setDialog3(false);

        setSelectedDuration(duration[0]);
        setNotesd('');
    }

    const setDuration = (e: any) => {
        setSelectedDuration(e.value);
        console.log(e.value.name)
        getReservations(0,e.value.code) 
        setCount(0)

    }
    
    const [count, setCount] = useState(0);
    const onLeftClick = () => {

        if (count > 0) {
            setCount(count - 1)
            setLoader(true);
            getReservations(count - 1, selectedDuration.code)

        }
        else {
            setCount(0)
        }

        console.log("left clicked", count);
    }

    const onRightClick = () => {

        if (count >= 0 && count < Math.ceil((dataRes?.total) / 10) - 1) {
            setCount(count + 1)
            setLoader(true);
            getReservations(count + 1, selectedDuration.code)
        }

        console.log("Right clicked", count,Math.ceil((dataRes?.total) / 10) - 1);

    }


    const onSearch = (e: any) => {
        console.log("search", e.target.value)
        setGlobalFilter(e.target.value)
        if (e.target.value !== "") {
            getReservations(0,  (e.target.value).replace("+", "%2B"))
        }
        else {
            console.log("no search phrase")
            getReservations(0)
        }


    }




    useEffect(() => {
        setLoading(true)
        setLoader(true)
        const page = 0;
        const pageSize = 10;
        getReservations(0)
    }, [refresh])


    const getReservations = (page: any, searchTxt?: any,) => {
        // let selectedDate = date2;
        // if (searchDate) {
        //     selectedDate = searchDate;
        // }
        // console.log("Filter data : ", selectedDate, searchTxt, page)
        restaurantStore.cancellationRequests(searchTxt ? searchTxt : "", page)
            .then(([success, data]) => {
                if (success) {
                    setData(data);
                    console.log("cancelation request data", data?.items);
                    setLoader(false);
                } else {
                    setErrorMsg(data.msg)
                    setLoader(false);
                }
            })
            .finally(() => {
                setLoading(false)
                setRefresh(false)
            })
    }
    const [accept, setAccept] = useState();

    const [reject, setReject] = useState();

    const [demoId, setDemoID] = useState();
    const actions = (tableData: any) => {
        return <div>
            {tableData.request_status==="Rejected"?<p className="red">Request Rejected</p>:
            tableData.request_status==="Accepted"?<p className="green">Request Accepted</p>:
            <div className="actions">
                <p onClick={()=>{setDialog(true);setAccept(tableData)}} className="accept"><i className="fas fa-check-circle green"></i>Accept</p> 
                <p onClick={()=>{setDialog3(true);setReject(tableData)}} className="reject"><i className="fas fa-times-circle red"></i>Reject</p>
            </div>}
        </div>
    }
    const acceptC = (id:any,type:any)=>{
        setLoader(true);
        restaurantStore.updateCancellation(id.id,type)
        .then(([success, data]) => {
            if (success) {
                console.log("accept cancelation update data", data);
                setCount(0)
                getReservations(0,duration[0].code);
                onHide();
                setLoader(false);
            } else {
                console.log(" accept cancelation update error data", data);
               
            }
        })
        .finally(() => {
            setLoading(false)
            setRefresh(false)
        })
        console.log("accept cancellation : ", id.id , type)

    }



    const RejectC = (id:any,type:any)=>{
        setLoader(true);
        restaurantStore.updateCancellation(id.id,type)
        .then(([success, data]) => {
            if (success) {
                console.log("reject cancelation update data", data);
                setCount(0)
                getReservations(0,duration[0].code);
                onHide();
                setLoader(false);
            } else {
                console.log(" reject cancelation update error data", data);
               
            }
        })
        .finally(() => {
            setLoading(false)
            setRefresh(false)
        })
        console.log("accept cancellation : ", id.id , type)

    }


    const [note, setNote] = useState();
    const total = (tableData: any) => {
        return `£ ${tableData.total_amount}`
    }
    const refund = (tableData: any) => {
        return `£ ${tableData.refund_amount}`
    }


    // const pageCount=data?Math.ceil(data.length/pageSize):0;

    // if(pageCount === 1) return null;

    // const pages= range(1,pageCount+1)


    const Mindate = () => {
        let today = moment().subtract(1, 'years').calendar();
        return new Date(today)
    }
    const Maxdate = () => {
        let today = moment().add(1, 'months').calendar();
        return new Date(today)
    }
    const calsearch = (e: any) => {
        let date = moment(e.target.value).format("YYYY-MM-DD").toString();
        setDate2(date)
        getReservations(0, date)
        // console.log("date check",e.target.value);
    }
    const globalSearch = () => {
        return (
            <div className="calHead">
                <div className="searchHold">
                    <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText autoComplete="off" className="SearchBar" maxLength={20} style={{ width: "95%" }} type="search" onChange={(e: any) => onSearch(e)}
                        placeholder="Search" />
                </div>
                <div className="dashboard-admin">
                            <Dropdown style={{ fontSize: '20px' }} onChange={setDuration} value={selectedDuration} options={duration} optionLabel="name" placeholder="Pending" />
                        </div>
                {/* <button className="calSearch"> Search</button> */}

                {/* <Calendar readOnlyInput dateFormat="dd/mm/yy" minDate={Mindate()} maxDate={Maxdate()} placeholder="Search By Date" style={{ width: '300px', height: '45px' }} id="icon" value={new Date(date2)} onChange={(e: any) => calsearch(e)} showIcon /> */}
            </div>

        );
    }



    return (
        <Fragment>
            <div className="breadcrum">
                <h2 onClick={() => history.goBack()} className="breadcrumText activeBreadcrum">SUBSCRIPTIONS</h2 >
                <h2 className="breadcrumText"> &gt; </h2>
            <h2 className="breadcrumText">CANCELLATION REQUESTS</h2>
            </div>
            <hr className="hr" />

            {loading ? <div><Loader setLoader={setLoader} visible={loader} /></div> : errorMsg ? <div className='error-msg'>{errorMsg}</div> : <div className="p-grid p-fluid">
                <div><Loader setLoader={setLoader} visible={loader} /></div>
                <div className="p-col-12 resList">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className="p-col-12">
                                {/* {(dataRes?.items).length === 0 ? <div className="nodata"><h1>No Reservation Data Available</h1><img src="assets/images/nodata.png" alt="nodata" /> </div> : */}
                                <DataTable value={dataRes?.items} header={globalSearch()} 
                                    responsive={true} rows={10} 
                                    emptyMessage="No Cancellation Requests Found" resizableColumns={true} columnResizeMode="expand">
                                    <Column field="id" header="Request ID" style={{ width: '100px',textAlign:"center",color:"#0052C3" }} />
                                    <Column field="request_date" header="Date" style={{ width: '100px',color:"#0052C3" }} />
                                    <Column field="restaurant_name" header="Restaurants" style={{ width: '150px' }} />
                                    <Column field="subscription_pack" header="Pack" style={{ width: '160px' }} />
                                    <Column body={total} header="Total" style={{ width: '120px' }} />
                                    <Column field="request_status" header="Status" style={{ width: '115px' }} />
                                    <Column body={refund} header="Refund" style={{ width: '90px' }} />
                                   {selectedDuration.name === "Pending"? <Column body={actions} header="Actions" style={{ width: '190px' }} />:null}
                                    {/* <Column body={deleteTemplate} header="Action" style={{ width: '80px', color: '#ED5200 !important' }} /> */}
                                </DataTable>
                                {/* } */}

                                <br />
                                <div style={{ textAlign: 'center', columnGap: '10px' }}>
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", marginRight: "10px", cursor: "pointer" }} className="fas fa-chevron-left" onClick={onLeftClick}></i>
                                    Page: {count + 1} of {dataRes.total>10?Math.ceil(dataRes?.total/10):1}
                                    <i style={{ padding: "5px 9px", borderRadius: "50%", backgroundColor: "#78787812", cursor: "pointer", marginLeft: "10px" }} className="fas fa-chevron-right" onClick={onRightClick}></i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Dialog visible={dialog} style={{ width: '358px' }} onHide={() => onHide()}>
                <div className="notes2">
                    <i onClick={onHide} className="pi pi-times close"></i>
                    <br />
                    <h3>Subscription Cancel Request</h3>
                    <br/>
                    <p>Please confirm</p>
                    <br/>
                    <button onClick={(e: any) => { e.preventDefault();acceptC(accept,6)}} type="submit" className="filled">YES</button>
                    <button onClick={(e: any) => { e.preventDefault(); onHide() }} className="outline">NO</button>
                </div>
            </Dialog>

            <Dialog visible={dialog3} style={{ width: '358px' }} onHide={() => onHide()}>
                <div className="notes2">
                    <i onClick={onHide} className="pi pi-times close"></i>
                    <br />
                    <h3>Are you sure?</h3>
                    <br/>
                    <p>You want to reject</p>
                    <br/>
                    <button onClick={(e: any) => { e.preventDefault();RejectC(reject,7)}} type="submit" className="filled">YES</button>
                    <button onClick={(e: any) => { e.preventDefault(); onHide() }} className="outline">NO</button>
                </div>
            </Dialog>
            <Dialog visible={dialog2} style={{ width: '358px' }} onHide={() => onHide()}>
                <div className="notes2">
                <i style={{fontSize:"50px"}} className="fas fa-check-circle green"></i>
                <br/>
                <h3 style={{marginTop:"25px"}}>Refund Initiated</h3>
                <p style={{marginTop:"5px"}}>Refund of £{accept?.refund_amount} has been initiated to “{accept?.restaurant_name}”.</p>
                </div>
            </Dialog>
        </Fragment>
    );
})