import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { useStore } from '../stores/useStore';
import { Checkbox } from 'primereact/checkbox';
import './Login.css';


const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`
const Login = observer((props) => {
	const [errorMsg, setErrorMsg] = useState(null);
	const { userSessionStore } = useStore();
	const history = useHistory();
	return <div className="login">
		<div className="loginDetails">

			{/* Form validations using formik and yup */}

			<Formik
				initialValues={{ email: '', password: '', isChecked: false }}
				validationSchema={yup.object({
					email:yup.string().email('Must be a valid email').max(255).required('Email is required'),

					password: yup.string()
					.min(5,'Invalid Password')
						.max(20, 'Enter valid Password')
						.required('Required'),
					isChecked: yup.bool()
				})}
				onSubmit={(values, { setSubmitting }) => {
					console.log(values.isChecked)
					userSessionStore.authenticate(values.email, values.password, values.isChecked)
						.then(([success, message]:any) => {
							if (success) {
								setErrorMsg(null)
								history.push('/')
								setTimeout(() => {
								history.go(0);
								}, 500);

							} else {
								setErrorMsg(message)
								setSubmitting(false)
							}
						})
				}}>
				{formik =>
					<Form>
						<div className="login-panel-content">
							<div className="login-bd">
								{/* <div className="p-col-6 loginImage">
									<img className='img' src="./assets/images/brand-bg.png" alt="brand-background" />
									<div className="brand-mask">
										<img className='brandlg' src="./assets/logo/purple bg.svg" alt="brand-logo" />
										<p className="powered-by">Powered by VenturesSky Ltd</p>
									</div>
								</div> */}
								<div className="p-col-12 ">
									<div style={{height:"100vh"}} className='p-grid form-control-group p-fluid'>
										<div className='p-col-12 loginDetails loginContent'>
											<h2 className="loginT">Login <span className="errorMsg"> {errorMsg}</span></h2>
											<label>Email</label>
											<ErrorMessage name='email' component={FormErrorMsg} />
											<Field name='email' autoComplete="off" as={InputText} />
											<br />
											<label>Password</label>
											<ErrorMessage name='password' component={FormErrorMsg} />
											<Field type='password' maxLength="20" name='password' as={InputText} />
											<br />
											{/* <Field type='checkbox' inputId="binary" name='isChecked' as={Checkbox} />
											<label htmlFor="binary">&nbsp;&nbsp;&nbsp; Remember Me</label> */}
											<Link to="forgot-password">
											<span className="forgotPw">Forgot Password?</span>
											</Link>
											<br />
											<br />
											<br />
											<Button style={{margin:0}} type='submit' disabled={formik.isSubmitting} label="LOGIN" />
											
											{/* <div className="login-footer">
												<div className="footer-items">
													<p>About Us</p>
			                    										<p>Terms &amp; Conditions</p>
													<p>Privacy Policy</p>
													<p>Contact Us</p>
												</div>
												<h6 className='footer-copyright'>&copy; Copyright 2021, VenturesSky Ltd</h6>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				}
			</Formik>
		</div>
	</div>
})

export default Login