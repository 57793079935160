import React, {Component} from 'react';
import {Button} from "primereact/button";
import { Card } from 'primereact/card'

export default class Error extends Component {

	render() {
		return <div className='page-container'>
			<div className="p-grid p-justify-center p-align-center">
				<div className="p-col-10">
					<Card title='Error Occured' >
						<p>Something went wrong.</p>
						<Button label="Back" onClick={() => { window.history.back() }} />
					</Card>
				</div>
			</div>
		</div>
		
	}
}