import React, { useState, useEffect, Fragment } from 'react';
import "./AdminDashboard.css";
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useStore } from '../stores/useStore';
import moment from 'moment'
let count = 100

function AdminDashboard(props: any) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [month, setMonth] = useState<any>([]);
    const [revenue, setRevenue] = useState<any>([]);
    const [refunds, setRefunds] = useState<any>([]);
    const { restaurantStore } = useStore();
    const [dates2, setDates2] = useState<any>([moment(moment().startOf('month'))?._d,moment()?._d]);
    
    const dashboardGraph = ()=>{
        restaurantStore.dashboardGraph()
        .then(([success, data]: any) => {
            if (success) {
                setErrorMsg(null);
                console.log(data)
                // console.log(data.dashboard_graph_list);
                data.dashboard_graph_list.map((data: any) => (
                    setMonth((prev: any) => [...prev, data.month])
                ))
                data.dashboard_graph_list.map((data: any) => (
                    setRefunds((prev: any) => [...prev, Math.round(data.refunds)])
                ))
                data.dashboard_graph_list.map((data: any) => (
                    setRevenue((prev: any) => [...prev, Math.round( data.revenue)])
                ))
            }
            else {
                setErrorMsg(data.msg)
            }
        })
    }
    if((refunds).length>0 && (revenue).length>0){
        if((Math.max(...revenue)) || (Math.max(...refunds))>count){
            count = Math.round(Math.max(...revenue)) +1000
        }
    }

    const multiAxisData = {
        labels: month,
        datasets: [{
            label: 'Revenue',
            backgroundColor: '#3EAF4F',
            yAxisID: 'y-axis-1',
            data: revenue
        },
        {
            label: 'Refunds',
            backgroundColor: '#0387E8',
            yAxisID: 'y-axis-2',
            data:refunds
        },
        ]
    };


    let multiAxisOptions = {
        responsive: true,
        tooltips: {
            mode: 'index',
            intersect: true
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                },
                gridLines: {
                    color: '#ebedef'
                }
            }],
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    min: 0,
                    max:count,
                    fontColor: '#495057'
                },
                gridLines: {
                    color: '#ebedef'
                }
            },
            {
                type: 'linear',
                display: false,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    drawOnChartArea: false,
                    color: '#ebedef'
                },
                ticks: {
                    min: 0,
                    max:count,
                    fontColor: '#495057'
                }
            }]
        },
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const [startDate,setStartDate]=useState<any>(moment(moment().startOf('month')).format("YYYY-MM-DD"))
    const [endDate,setEndDate]=useState<any>(moment().format("YYYY-MM-DD"))

    const[summary,setSummary]=useState();
    const getSummary = (start:any,end:any)=>{
        restaurantStore.dashboardSummary(start,end)
        .then(([success, data]: any) => {
            if (success) {
                setSummary(data.dashboard_summary_list)
            console.log("success summary : ",data.dashboard_summary_list)                
            }
            else {
            console.log("error summary : ",data.dashboard_summary_list)                
              
            }
        })
    }
    const loadData = ()=>{
        dashboardGraph();
        getSummary(startDate,endDate)
    }
    useEffect(() => {
        loadData();
        
    },[])
    const selectedDates =(e:any)=>{
        
        setDates2(e.value)
        setStartDate(e.value[0])
        setEndDate(e.value[1])
        
        if(e.value[0]  && e.value[1] ){
            console.log("valid date added",moment(e.value[0]).format("YYYY-MM-DD"),moment(e.value[1]).format("YYYY-MM-DD"))
            getSummary(moment(e.value[0]).format("YYYY-MM-DD"),moment(e.value[1]).format("YYYY-MM-DD"))
        }
    }

    return (
        <Fragment>

            <div className="dashboard-admin">
                <div className="breadcrum">
                    <h2 className="breadcrumText">Dashboard</h2 >
                </div>
                <hr className="hr" />

                <div className="breadcrum">
                        <Calendar dateFormat="yy/mm/dd" id="range" value={dates2} onChange={(e:any) => selectedDates(e)} selectionMode="range" readOnlyInput />
                        <span style={{backgroundColor:"#5100ea",padding:"5px 10px", borderRadius:"7px",marginLeft:"-50px",zIndex:2,pointerEvents:"none"}}><i style={{color:"white",fontSize:"25px"}} className="far fa-calendar-alt"></i></span>
                </div>

                <div className="dashboard">
                    <div className="card1">
                        <h2 className="CardHead">SUBSCRIPTIONS</h2>
                        <br/>
                        <h1 className="CardNumber green">{summary?.paid_count}</h1>
                    </div>
                    <div className="card2">
                        <h2 className="CardHead">CANCELLED SUBSCRIPTIONS</h2>
                        <br/>
                        <h1 className="CardNumber red">{summary?.cancellations_count}</h1>
                    </div>
                    <div className="card3">
                        <h2 className="CardHead">FREE SUBSCRIPTIONS</h2>
                        <br/>
                        <h1 className="CardNumber green">{summary?.free_count}</h1>
                    </div>
                </div>
                <br />
                <br />
                <hr className="hr" />
                <div className="Admingraph" >
                    <div className="summary">
                        <h4>Summary <span>{errorMsg}</span></h4>
                        <div className="legends">
                            <p><span className="greenc circle">B</span> <span>Revenue</span></p>
                            <p><span className="bluec circle">B</span> <span>Refunds</span></p>
                        </div>
                    </div>
                    <Chart type="bar" data={multiAxisData} options={multiAxisOptions} />
                </div>

            </div>

        </Fragment>
    )
}

export default AdminDashboard
