import classNames from 'classnames';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// import { observer } from 'mobx-react-lite'
import { AppMenu } from './AppMenu';
// import { EmptyPage } from './pages/EmptyPage';
// import { FloorPlanList } from './pages/FloorPlanList';
import { DemoRequests} from './pages/DemoRequests';
import { ReservationForm } from './pages/ReservationForm';
import {RestaurantList } from './pages/RestaurantList';
import PaymentDetails from './pages/paymentDetails';
import AdminDashboard from './pages/AdminDashboard';
import { userSessionStoreHoc } from './Hoc';
import Login from './pages/Login';
import AdminProfile from './pages/AdminProfile';
// import ManageTable from './pages/manageTable';
 import GuestBook from './list/guestBook';
import AppTopbar from './AppTopbar';
import Thankyou from './pages/thankyou';
import SideNav from './pages/sidenav';
import ChangePassword from './pages/ChangePassword';
import Reservation from './list/Reservation';
import Footer from './pages/footer';
import ProfileSettings from './pages/profileSettings';
import ForgotPassword from './pages/forgotPassword';
import AccessDenied from './pages/AccessDenied';
import Success from './pages/paymentSuccess';
import Fail from './pages/paymentFail';
import {CancellationRequests} from './pages/CancellationRequests';
import SubscriptionPlans from './pages/SubscriptionPlans'
import Subscriptions from './pages/subscriptions';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutMode: 'static',
            lightMenu: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            isRTL: false,
            topbarColor: 'layout-topbar-light',
            inlineUser: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rightPanelMenuActive: null,
            inlineUserMenuActive: false,
            menuActive: false,
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onRightMenuButtonClick = this.onRightMenuButtonClick.bind(this);
        this.onRightMenuClick = this.onRightMenuClick.bind(this);
        this.isAdminSite = this.props.userSession.user;
        this.createMenu();
        console.log("is superuser -- ",this.isAdminSite);
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }
        if (!this.rightMenuClick) {
            this.setState({ rightPanelMenuActive: false });
        }
        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            if (this.state.overlayMenuActive || this.state.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.setState({ menuHoverActive: false });
            this.unblockBodyScroll();
        }
        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightMenuClick = false;
    }
    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            topbarMenuActive: false,
            rightPanelMenuActive: false
        }));
        if (this.isOverlay()) {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        if (this.isDesktop())
            this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
        else {
            this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
            if (this.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }
        event.preventDefault();
    }
    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });
        event.originalEvent.preventDefault();
    }
    onMenuClick(event) {
        this.menuClick = true;
    }
    blockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }
    unblockBodyScroll() {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
    onRightMenuButtonClick(event) {
        this.rightMenuClick = true;
        this.setState({ rightPanelMenuActive: !this.state.rightPanelMenuActive });

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRightMenuClick(event) {
        this.rightMenuClick = true;
    }

    hideOverlayMenu() {
        this.setState({
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }
    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 896;
    }

    isMobile() {
        return window.innerWidth <= 1025;
    }

    isStatic() {
        return this.state.layoutMode === 'static';
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(menuMode) {
        this.setState({
            layoutMode: menuMode,
            staticMenuDesktopInactive: false,
            overlayMenuActive: false
        });
    }

    changeTopbarColor(topbarColor, logo) {
        this.setState({ topbarColor: topbarColor });
        const topbarLogoLink = document.getElementById('topbar-logo');
        topbarLogoLink.src = 'assets/layout/images/' + logo + '.svg';
    }

    changeTheme(theme) {
        this.changeStyleSheetUrl('layout-css', theme, 'layout');
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    createMenu() {

        if (!this.isAdminSite) {
            this.menu = [
                { label: 'SignUp', icon: 'pi pi-fw pi-clone', to: '/signup' },
            ]
        } else {
            this.menu = [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
                { label: 'Reservations', icon: 'pi pi-fw pi-ticket', to: '/reservationlist' },
                { label: 'Manage Tables', icon: 'pi pi-fw pi-plus', to: '/ManageTables' },
                { label: 'Guest Book', icon: 'pi pi-fw pi-bookmark', to: '/guestbooklist' },
                { label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/restaurantlist' },
                { label: 'Log Out', icon: 'pi pi-fw pi-sign-out', to: '/create-floor-plan' },
            ]
        }
    }

    render() {
        const layoutClassName = classNames('layout-wrapper', {
            'layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-slim': this.state.layoutMode === 'slim',
            'layout-menu-light': this.state.lightMenu === true,
            'layout-menu-dark': this.state.lightMenu === false,
            'layout-overlay-active': this.state.overlayMenuActive,
            'layout-mobile-active': this.state.staticMenuMobileActive,
            'layout-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-rtl': this.state.isRTL
        }, this.state.topbarColor);

        // const activeNav = (e) => {
        //     console.log("ActiveNav", e.currentTarget)
        //     let activeOption = e.currentTarget.children[0];
        //     activeOption.setAttribute("style", "background-color:#510AEA;color: #f6f2fe;")
        //     console.log(activeOption)
        // }
        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <AppTopbar topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem} inlineUser={this.state.inlineUser}
                    onRightMenuButtonClick={this.onRightMenuButtonClick} onMenuButtonClick={this.onMenuButtonClick}
                    onTopbarMenuButtonClick={this.onTopbarMenuButtonClick} onTopbarItemClick={this.onTopbarItemClick} />

                <div className='layout-menu-container' onClick={this.onMenuClick}>
                    <div className="menu-scroll-content">

                        {/* <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                            layoutMode={this.state.layoutMode} active={this.state.menuActive} /> */}

                        <div>
                            <SideNav />
                        </div>


                    </div>
                </div>
                <div className="layout-main">
                    <div className="layout-content">
                        <Switch>
                            <Route path="/" exact>
                                <AdminDashboard />
                            </Route>
                            <Route path="/reservationform/new">
                                <ReservationForm />
                            </Route>
                            <Route path="/reservationform/edit/:id">
                                <ReservationForm />
                            </Route>
                            <Route path="/restaurantlist" >
                                <RestaurantList />
                            </Route>
                            <Route path="/demorequests">
                                <DemoRequests/>
                            </Route>
                            <Route path="/reservation">
                                <GuestBook />
                            </Route>
                            <Route path="/cancellationrequests">
                                <CancellationRequests />
                            </Route>
                            <Route path="/subscriptionplans">
                                <SubscriptionPlans />
                            </Route>
                            

                            <Route path="/thankyou">
                                <Thankyou />
                            </Route>
                            <Route path="/profile-settings">
                                <ProfileSettings />
                            </Route>
                            <Route path="/change-password">
                                <ChangePassword />
                                </Route>
                          
                            {/* <Route path="/floorplanlist/:id">
                                <FloorPlanList />
                            </Route> */}
                           
                            <Route path="/guest-book">
                                <Reservation />
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/adminprofile">
                                <AdminProfile />
                            </Route>
                            <Route path="/subscriptions">
                                <Subscriptions />
                            </Route>
                           
                            {/* <Route path="/ManageTables">
                                <ManageTable />
                            </Route> */}
                            
                            <Route path="/footer">
                                <Footer />
                            </Route>
                            <Route path="/payment-success">
                                <Success />
                            </Route>
                            <Route path="/AddCard">
                                <PaymentDetails />
                            </Route>
                            <Route path="/payment-fail">
                                <Fail />
                            </Route>
                            {/* <Route path="/tables">
                                <Tables />
                            </Route> */}
                            
                            <Route>
                                <Redirect to="/404" />
                            </Route>
                            <Route path="forgot-password">
                                <ForgotPassword />
                            </Route>
                        </Switch>
                    </div>
                    {/* <Footer /> */}
                </div>
                
                <div className="layout-content-mask">
                    
                </div>
                {/* <AppTopbar topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem} inlineUser={this.state.inlineUser}
                    onRightMenuButtonClick={this.onRightMenuButtonClick} onMenuButtonClick={this.onMenuButtonClick}
                    onTopbarMenuButtonClick={this.onTopbarMenuButtonClick} onTopbarItemClick={this.onTopbarItemClick} /> */}
                
            </div>
        );
    }
}


export default userSessionStoreHoc(App);
