import React, { useState, useEffect, Fragment } from 'react';
import styles from './AdminProfile.module.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStore } from "../stores/useStore";
import Toaster from '../components/Toaster/toaster';
import { toast } from 'react-toastify';

// import {getRefreshToken} from '../stores/apiClient'

toast.configure();

const AdminProfile = () => {
    const [bi, setBI] = useState(true);
    const [showRL, setShowRL] = useState(false);
    const { restaurantStore } = useStore();
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    
    //------Get user------
   
    const user = () => {
        let id = sessionStorage.getItem('user-id');
        restaurantStore.getUser(id)
            .then(([success, data]) => {
                if (success) {
                    setFname(data.first_name);
                    setLname(data.last_name);
                    setEmail(data.email);
                    setMobile(data.mobile);
                    console.log("success", data)
                } else {
                    console.log("get user error responce : ", data)
                }
            })

    }



    useEffect(() => {
        user();
    }, [])


    return (
        <Fragment>
            <div className={styles.adminprofile}>
                <div className={styles.adminDetails}>
                    <div className={styles.myProfile}>
                        <h1>MY PROFILE</h1>
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.adminRest}>
                        <div className={styles.profileDetails}>

                          <div className={styles.details}>
                                <div className={styles.imgBackground}>
                                    <div className={styles.imgBorder}>
                                        <img className={styles.adminImg} src="./assets/images/admin3.png" alt="admin-img" />
                                    </div>
                                    <div className={styles.changePI} hidden>
                                        <i className="pi pi-camera"></i>
                                    </div>
                                </div>
                                <h6>{fName + ' ' + lName}</h6>
                                <Link to="/change-password">
                                    <button className={styles.filled}>CHANGE PASSWORD</button>
                                </Link>
                            </div> 
                        </div>
                        <div className={styles.basicInformation}>
                            <h5>Basic Information</h5>
                            <div className={styles.profileData}>
                                    <p>First Name <span>{fName}</span></p>
                                    <p>Last Name <span>{lName}</span></p>
                                    <p>Email <span>{email}</span></p>
                                    <p>Mobile <span>{mobile}</span></p>
                                
                            </div>

                        </div>

                    </div>
                    <hr className={styles.vr} />

                </div>
            </div>
        </Fragment>)
}
export default AdminProfile



