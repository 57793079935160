import React, { Fragment } from 'react';
import "./footer.css";


const Footer = () => {
	return <Fragment>
		<div className="footer">
			<hr className="hr" />
			<br />
			<div className="footer-items">
				<p>About Us</p>
				<p>Terms &amp; Conditions</p>
				<p>Privacy Policy</p>
				<p>Contact Us</p>
			</div>
			<h6 className='footer-copyright'>&copy; Copyright 2021, VenturesSky Ltd</h6>
		</div>

	</Fragment>
}

export default Footer