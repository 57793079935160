import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
    timeout: 150000
}
);

async function getAccessToken() {
    return sessionStorage.getItem('access');
}

// Request interceptor for auth header

// apiClient.interceptors.request.use(async (config) => {
//     config.headers['Authorization'] = 'Bearer ' + await getAccessToken();
//     return config;
// });


apiClient.interceptors.response.use(undefined,
   function (error) {
    const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry ) {
            // sessionStorage.clear();
            // sessionStorage.clear();
            originalRequest._retry = true;
            return apiClient.post('/api/v1/refresh',{},
           {
            headers:{
                Authorization: 'Bearer ' + sessionStorage.getItem('refresh'),
            }
               
           }).then((res: any) => {
               console.log("refresh token data : ","wait for 1 sec to page reload", res)
            //    alert(`refresh token : ${res.data}`)
               sessionStorage.setItem('access', res.data.access_token)
               apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
               setTimeout(() => {
               window.history.go(0);
               }, 1000);
               return apiClient(originalRequest);
           })
           .catch((err: any) => {
            let errorMsg = err.response ? err.response.data.detail : err.message
            console.log("refresh token error data : ", errorMsg)
            return errorMsg
            })
            }
        return Promise.reject(error);
    }
)
export default apiClient;
