import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Reservation.css'
import ProductService from './productService'
import data from './products-small.json';

const Reservation = () => {
   
    const [products, setProducts] = useState([
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},
        {id: "1000",name: "John Doe",address: "76 Nottingham Rd ABERDULAIS SA10 6TA ,UK",country: "United Kingdom",email:"john.doegmail.com",mobile: "(404) 556 4444",telephone:98228848984,tags: "VIP"},

    ]);
    return (
        <div >
            <div className="guestBook" >
            
            <div className="card">
                <DataTable value={products} paginator sortMode="multiple" rows={10} rowsPerPageOptions={[10,20,50]}>
                    <Column field="name" header="Name" sortable  ></Column>
                    <Column field="address" header="Address" sortable  ></Column>
                    <Column field="country" header="Country" sortable ></Column>
                    <Column field="email" header="Email" sortable  ></Column>
                    <Column field="mobile" header="Mobile" sortable  ></Column>

                    <Column field="telephone" header="Telephone" sortable  ></Column>

                    <Column field="tags" header="Tags" sortable ></Column>

                </DataTable>
            </div>
            </div>
        </div>
    );
}


export default Reservation