import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProductService from './productService'
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import "./guestBook.css";



import data from './products-small.json';
const GuestBook = () => {
    const deleteTemplate = (rowData) => {
        return <a href='#' style={{ color: 'red' }} >Delete</a>
    }

   
    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Date</span>
                <span>{rowData.date}</span>
            </React.Fragment>
        );
    }



    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month === 0) ? 11 : month - 1;
    let prevYear = (prevMonth === 11) ? year - 1 : year;
    let nextMonth = (month === 11) ? 0 : month + 1;
    let nextYear = (nextMonth === 0) ? year + 1 : year;

    const [date2, setDate2] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const dt = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const onDateChange = (e) => {
        // dt.current.filter(e.value, 'date', 'custom');
        setDate2(e.value);
    }



    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === formatDate(filter);
    }

    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }


    const [products, setProducts] = useState([
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },

        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },

        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },
        { id: "1000", date: "2021-02-06", time: "08:00 AM", guests: "John Doe", partysize: "23", mobile: 7799245633, },

      

    ]);
    const productService = new ProductService();

    const statusFilter = <Calendar id="icon" value={date2} onChange={e => onDateChange(e)} showIcon />;


    // useEffect(() => {
    //     productService.getProductsSmall().then(data => setProducts(data));
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const dateFilter = <Calendar value={date2} onChange={onDateChange} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="Registration Date" />;



    return (
        <div>
            <div className="guestBook">

                <div className="card">
                    <DataTable value={products} paginator sortMode="multiple" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column field="date" header="Date" sortable filter  filterElement={dateFilter} filterFunction={filterDate} />
                        <Column field="time" header="Time" sortable filter ></Column>
                        <Column field="guests" header="Guests" sortable filter ></Column>
                        <Column field="partysize" header="Party Size" sortable filter ></Column>
                        <Column field="mobile"  header="Mobile" sortable filter ></Column>

                        <Column body={deleteTemplate} header="Actions" sortable style={{ width: '80px' }} ></Column>

                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default GuestBook