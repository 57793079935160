import React from 'react';
import './accessDenied.css';
const AccessDenied =()=>{

		return <div className='noAccess'>
			<div>
			<h1>403</h1>
			<h3>Access Denied</h3>
			<p>We are sorry, but you don’t have permission to access this page You can go back to <span onClick={()=>window.history.back()}>previous page</span></p>
			<img src="/assets/images/Login-bro.svg" alt="access denied"/>
			</div>
		</div>

}
export default AccessDenied