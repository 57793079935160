import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './subscriptions.css'
import cancelPln from "../images/Cancel-pana.svg";
import subscriptionPln from "../images/Subscriber-pana.svg"

import { useStore } from "../stores/useStore";


const Subscriptions= () => {
	
    return <Fragment>
        <div className="settingsBody">
            <div className="breadcrum">
                <h2 className="breadcrumText">Subscriptions</h2 >

            </div>
            <hr className="hr" />
            <div className="settingsCont">
                <Link to="/cancellationrequests">
                    <div className="displayBtns">
                        <img className="settingsImg" src={cancelPln} alt="manage Features" />
                        <p>Cancellation Requests</p>
                        
                    </div>
                </Link>
                <Link to='/subscriptionplans'>
                    <div className="displayBtns">
                        <img className="settingsImg2" src={subscriptionPln} alt="manage Features" />
                        <p>Subscription Plans</p>
                        
                    </div>
                </Link>
            </div>
        </div>
      
    </Fragment>
}

export default  Subscriptions

