import { ErrorMessage, Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { AutoComplete } from 'primereact/autocomplete';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'primereact/spinner';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import moment from 'moment';
import { useStore } from "../stores/useStore";
import { Steps } from 'primereact/steps';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';

const FormErrorMsg = styled.span`
    padding-left: 0px;
    color: red;
    display: 'inline-block';
    @media (max-width: 40em) {
    padding-left: 0;
    position: relative;
    top: 4px;
 }`

export const ReservationForm = observer((props: any) => {
    const { restaurantStore } = useStore();
    const history = useHistory()
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null)
    const [guestList, setGuestList] = useState([])
    const [filteredGuestList, setFilteredGuestList] = useState<any[]>([]);
    const [selectedGuest, setSelectedGuest] = useState('')
    const [timeSlots, setTimeSlots] = useState<any[]>([])
    const editMode = id ? true : false;
    const [restData, setRestData] = useState([])
    const [restOptions, setRestOptions] = useState([])
    const [formData, setFormData] = useState<any>({})
    const [activeIndex, setActiveIndex] = useState(0)
    const [newGuest, setNewGuest] = useState(true)
    const [buttonDisable, setButtonDisable] = useState(false)
    const interactiveItems = [{ label: 'Date and Time' }, { label: 'Guest Details' }, { label: 'Review and Confirm' }]

    useEffect(() => {
        setLoading(true)
        let options: any = [{}]
        restaurantStore.getRestaurantList()
            .then(([success, resData]) => {
                if (success) {
                    setRestData(resData)
                    options = resData.map((item: any) => ({ label: item.name, value: item.id }))
                    setRestOptions(options)
                } else {
                    setErrorMsg(resData)
                }
            })
            .then(() => {
                if (editMode) {
                    restaurantStore.getReservation(Number(id))
                        .then(([success, data]) => {
                            if (success) {
                                let bookedDate = moment(data.booked_date).toDate()
                                data.booked_date = bookedDate
                                data.guest.tags = data.guest.tags ? data.guest.tags.split(',') : ''
                                setFormData(data)
                                getTimeSlots(data.rest_id, bookedDate)
                            }
                        })

                } else {
                    setFormData({
                        booked_date: '', time: '', party_size: '', shift: '', status: '', rest_id: '', guest_id: null, guest: {
                            name: '', address: '', country: countries[0].value, email: '', mobile: '', phone: '', tags: '', notes: '', status: '', rest_id: null
                        }
                    })

                }
            })
            .then(() => {
                restaurantStore.getGuestList()
                    .then(([success, data]) => {
                        if (success) {
                            setGuestList(data)
                        } else {
                            setErrorMsg(data)
                        }
                    })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    function getTimeSlotsWithRest(restId: number, formik: any) {
        getTimeSlots(restId, formik.values.booked_date)
        formik.setFieldValue('time', '')
    }

    function getTimeSlotsWithDate(resvDate: Date, formik: any) {
        getTimeSlots(formik.values.rest_id, resvDate)
        formik.setFieldValue('time', '')
    }

    function getTimeSlots(restId: number, resvDate: Date) {
        if (restId && resvDate) {
            restaurantStore.getTimeSlots(restId, resvDate)
                .then(([success, resData]) => {
                    if (success) {
                        setTimeSlots(resData)
                        setButtonDisable(false)
                    } else {
                        setTimeSlots([])
                        setButtonDisable(true)
                    }
                })
        } else {
            return []
        }
    }

    const searchGuestList = (event: any) => {
        let results: any[] = []
        if (event.query.length !== 0) {
            results = guestList.filter((item: any) => {
                return item.name.toLowerCase().startsWith(event.query.toLowerCase()) || item.mobile?.startsWith(event.query)
            })
        }
        setFilteredGuestList(results)
    }

    const guestSearchTemplate = (data: any) => {
        return (
            <div className="p-clearfix">
                <div style={{}}>{[data.name, data.mobile].filter(val => val?.length).join(' - ')} </div>
            </div>
        );
    }

    function fillGuestForm(data: any, formik: any) {
        formik.setFieldValue('guest', data)
        formik.setFieldValue('guest_id', data.id)
        let tags = data.tags ? data.tags.split(',') : ''
        formik.setFieldValue('guest.tags', tags)
    }

    function clearGuestForm(formik: any) {
        setSelectedGuest('')
        formik.setFieldValue('guest', { name: '', address: '', country: countries[0].value, email: '', mobile: '', phone: '', tags: '', notes: '', status: '', rest_id: null }, false)
        formik.setFieldValue('guest_id', null)
    }

    const tags = [
        { label: 'Vegetarian', value: 'Vegetarian' },
        { label: 'VIP', value: 'VIP' },
        { label: 'Infant', value: 'Infant' },
    ];

    const countries = [
        { label: 'United Kingdom', value: 'United Kingdom' },
        { label: 'India', value: 'India' },
    ];

    const menuItems = [
        { label: 'Reservation', command: () => { history.push('/reservationlist') } },
        { label: editMode ? 'Edit' : 'New' }
    ]

    const home = { icon: 'pi pi-home', url: '/' }
    return (
        <>
            <BreadCrumb model={menuItems} home={home} />
            {loading ? <div>Loading... </div> :
                restData.length === 0 ? <div>No restaurant available for reservation</div> :
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="p-grid p-justify-between">
                                    <div className="p-col">
                                        <h1> {editMode ? 'Edit' : 'New'} Reservation</h1>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <Steps style={{ marginBottom: '30px' }} model={interactiveItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} />
                                    </div>
                                </div>
                                <Formik
                                    initialValues={formData}
                                    enableReinitialize={true}
                                    validationSchema={yup.object({
                                        rest_id: yup.number()
                                            .required('Required'),
                                        booked_date: yup.date()
                                            .required('Required'),
                                        time: yup.string()
                                            .required('Select a Time'),
                                        party_size: yup.number()
                                            .typeError("Must be a number")
                                            .positive("Can't start with Zero or  minus")
                                            .integer(" Can't include a decimal point")
                                            .required('Required'),
                                        guest: yup.object({
                                            name: yup.string()
                                                .max(30, 'Must be 30 characters or less')
                                                .required('Required'),
                                            address: yup.string()
                                                .max(100, 'Must be 100 characters or less')
                                                .required('Required'),
                                            country: yup.string()
                                                .max(30, 'Must be 30 characters or less')
                                                .required('Required'),
                                            email: yup.string()
                                                .max(30, 'Must be 30 characters or less')
                                                .email('Please enter a valid email')
                                                .required('Required'),
                                            mobile: yup.string()
                                                .required('Required')
                                                .matches(new RegExp(/^\+?\(?(\d{1,4}[\s-)]*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}[\s.-]?(x\d{1,10})?$/),
                                                    "Mobile number is not valid"),
                                            phone: yup.string()
                                                .matches(new RegExp(/^\+?\(?(\d{1,4}[\s-)]*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}[\s.-]?(x\d{1,10})?$/),
                                                    "Mobile number is not valid"),
                                            notes: yup.string()
                                                .max(30, 'Must be 30 characters or less'),
                                        }),

                                    })}

                                    onSubmit={(values, formik) => {
                                        if (Array.isArray(values.guest.tags)) {
                                            values.guest.tags = values.guest.tags.join(',')
                                        }
                                        if (editMode) {
                                            restaurantStore.updateReservation(Number(id), values)
                                                .then(([success, data]) => {
                                                    history.push('/reservationlist')
                                                })

                                        } else {
                                            restaurantStore.createReservation(values)
                                                .then(([success, data]) => {
                                                    history.push('/reservationlist')
                                                })
                                        }
                                    }}>

                                    {formik =>
                                        <Form autoComplete="off">
                                            {activeIndex === 0 &&
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6 p-sm-12 p-lg-6">

                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-4 ">
                                                                <label>Restaurant Name</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-6 ">
                                                                <Field name='rest_id' as={Dropdown} options={restOptions} placeholder='Select Restaurant'
                                                                    onChange={(event: any) => { formik.handleChange(event); getTimeSlotsWithRest(event.value, formik) }}
                                                                />
                                                                <ErrorMessage name='rest_id' component={FormErrorMsg} />
                                                            </div>
                                                        </div>

                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-4">
                                                                <label>Party Size</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <Field name='party_size' as={Spinner} min={1} keyfilter="pint" />
                                                                <ErrorMessage name='party_size' component={FormErrorMsg} />
                                                            </div>
                                                        </div>

                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-4">
                                                                <label>Date</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <Field as={Calendar} name='booked_date'
                                                                    minDate={moment().toDate()} dateFormat="dd/mm/yy" showIcon={true}
                                                                    onSelect={(event: any) => {
                                                                        getTimeSlotsWithDate(event.value, formik)
                                                                    }}
                                                                />
                                                                <ErrorMessage name='booked_date' component={FormErrorMsg} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="p-col-12 p-md-6 p-sm-12 p-lg-6">
                                                        {buttonDisable === false ? <ErrorMessage name='time' component={FormErrorMsg} /> : ''}
                                                        <TimeSlots data={timeSlots} formik={formik} />
                                                        <span style={{ color: 'red' }}>{buttonDisable === true ? "No Shift is Available" : ''}</span>
                                                    </div>
                                                </div>
                                            }
                                            {activeIndex === 1 &&
                                                <div className="p-grid">
                                                    {editMode ? <div className='p-col-12 p-md-1'></div> : <div className='p-col-12 p-md-3'>
                                                        <div style={{ marginBottom: '20px' }}>
                                                            <RadioButton value={true} name="newGuest" onChange={(e) => { setNewGuest(e.value); clearGuestForm(formik) }} checked={newGuest} />
                                                            <span style={{ paddingLeft: '20px' }}>New Guest</span>
                                                        </div>
                                                        <div style={{ marginBottom: '20px' }}>
                                                            <RadioButton value={false} name="newGuest" onChange={(e) => { setNewGuest(e.value); clearGuestForm(formik) }} checked={!newGuest} />
                                                            <span style={{ paddingLeft: '20px' }}>Search Guest</span>
                                                        </div>

                                                        {!newGuest && <div>
                                                            <AutoComplete field='name' value={selectedGuest} suggestions={filteredGuestList} completeMethod={searchGuestList}
                                                                placeholder="Guest name or phone" itemTemplate={guestSearchTemplate}
                                                                onSelect={(e) => { fillGuestForm(e.value, formik) }}
                                                                onChange={(e) => { setSelectedGuest(e.value) }}
                                                            />
                                                        </div>}
                                                    </div>}

                                                    <div className='p-col-1'></div>
                                                    <div className='p-col-12 p-md-8'>
                                                        <div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Name</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.name' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.name' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Address</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.address' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.address' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Mobile Number</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.mobile' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.mobile' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Telephone</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.phone' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.phone' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Email</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.email' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.email' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Country</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.country' as={Dropdown} options={countries} placeholder="Select Country" />
                                                                    <ErrorMessage name='guest.country' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Tags</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.tags' as={MultiSelect} options={tags} placeholder="Select Tags" />
                                                                    <ErrorMessage name='guest.tags' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Notes</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-6">
                                                                    <Field name='guest.notes' autoComplete="off" as={InputText} />
                                                                    <ErrorMessage name='guest.notes' component={FormErrorMsg} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {activeIndex === 2 &&
                                                <ReservationSummary data={formik.values} restData={restData} />
                                            }
                                            {activeIndex === 0 && <div className="p-grid p-justify-center form-action-container" >
                                                <div className="p-col-3 " >
                                                    <Button type='button' label='Next' disabled={buttonDisable} onClick={(e) => {
                                                        const page1_fields = ['rest_id', 'party_size', 'booked_date', 'time']
                                                        page1_fields.forEach((item: string) => formik.setFieldTouched(item))
                                                        formik.validateForm()
                                                            .then((errors) => {
                                                                if (page1_fields.every((item: string) => !(item in errors))) {
                                                                    setActiveIndex(1)
                                                                }
                                                            })
                                                    }} className='p-button-primary' />
                                                </div>
                                            </div>}

                                            {activeIndex === 1 && <div className="p-grid p-justify-center form-action-container">
                                                <div className="p-col-3 " >
                                                    <Button type='button' label='Previous' onClick={e => setActiveIndex(0)} className='p-button-secondary' />
                                                </div>
                                                <div className="p-col-3 " >
                                                    <Button type='button' label='Next' onClick={e => {
                                                        const page2_fields = ['guest.name', 'guest.address', 'guest.mobile', 'guest.phone', 'guest.email', 'guest.country', 'guest.tags', 'guest.notes', 'guest.status']
                                                        page2_fields.forEach((item: string) => formik.setFieldTouched(item))
                                                        formik.validateForm()
                                                            .then((errors) => {
                                                                if (Object.keys(errors).length === 0) {
                                                                    setActiveIndex(2)
                                                                }
                                                            })
                                                    }} className='p-button-primary' />
                                                </div>
                                            </div>}

                                            {activeIndex === 2 &&
                                                <div className="p-grid p-justify-center form-action-container">
                                                    <div className="p-col-3 " >
                                                        <Button type='button' label='Previous' onClick={e => setActiveIndex(1)} className='p-button-secondary' />
                                                    </div>
                                                    <div className="p-col-3 " >
                                                        <Button type='submit' disabled={formik.isSubmitting} label={editMode ? 'Update' : 'Save'} className='p-button-primary' />
                                                    </div>
                                                    <div className="p-col-3" >
                                                        <Button type='button' label="Cancel" className='p-button-secondary' onClick={(e) => history.goBack()} />
                                                    </div>
                                                </div>
                                            }
                                        </Form>
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
})

const TimeSlots = (props: any) => {
    return (
        props.data.map((item: any, index: number) => {
            return (<div key={item.name + '.' + index} style={{ padding: '0 10px 10px 10px' }}>
                <div key={item.name} style={{ margin: '5px 0' }}>{item.name}</div>
                {item.slot.map((time: string, index: number) => {
                    let className = props.formik.values.time === time ? "p-button-rounded p-button-primary" : "p-button-rounded p-button-secondary"
                    return <div key={time + '.' + index} style={{ display: 'inline-block', margin: '4px' }}> <Button type='button' onClick={(e) => { props.formik.setFieldValue('time', time) }} label={time} className={className} style={{ width: '60px' }} /></div>
                })}
            </div>)

        })
    )

}

const ReservationSummary = (props: any) => {
    const restaurant = props.restData.find((item: any) => item.id === props.data.rest_id)
    return (
        <div className="p-grid">
            <div className='p-col-12 p-md-6'>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Restaurant
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {restaurant.name}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Booked Date
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {moment(props.data.booked_date).format('DD/MM/YYYY')}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Time
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.time}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Party size
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.party_size}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Guest Name
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.name}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Address
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.address}
                    </div>
                </div>
            </div>

            <div className='p-col-12 p-md-6'>
                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Mobile Number
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.mobile}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Telephone
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.phone}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Email
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.email}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Country
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.country}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Tags
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {Array.isArray(props.data.guest.tags) && props.data.guest.tags.join(',')}
                    </div>
                </div>

                <div className='p-grid'>
                    <div className='p-col-12 p-md-6'>
                        Notes
                    </div>
                    <div className='p-col-12 p-md-6' style={{ fontWeight: 'bold' }}>
                        {props.data.guest.notes}
                    </div>
                </div>
            </div>
        </div>
    )
}