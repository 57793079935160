import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router";
import "./profileSettings.css";
import { Dialog } from 'primereact/dialog';
import Markholiday from './markholiday';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStore } from "../../src/stores/useStore";
import { InputSwitch } from 'primereact/inputswitch';
import Toaster from '../components/Toaster/toaster';
import { idText } from 'typescript';
import { InputMask } from 'primereact/inputmask';
import { AnyAaaaRecord } from 'dns';
import { ToastContainer, toast } from 'react-toastify';


const FormErrorMsg = styled.span`
 color: red;
 float:right;
 font-size:12px;
`

toast.configure()
const ProfileSettings = () => {

	// const [date14, setDate14] = useStaate(null);
	const [titleRes, setTitl] = useState<any>();
	const [fromDt, setFromDate] = useState<any>();
	const [toDt, setToDate] = useState<any>();
	const [fromTm, setFromTime] = useState<any>();
	const [toTm, setToTime] = useState<any>();
	const [closeAllDay, setCloseAllDay] = useState(false);
	const [Ttype, setTtype] = useState("");
	const [msg, setMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState(null);

	const [bFromdate, setBFromDate] = useState('')
	const [bTodate, setBToDate] = useState('')
	const [bFromTime, setBFromTime] = useState('')

	const [bTotime, setBTotime] = useState('')
	const [fTime, setAvailableFromtime] = useState('')
	const [tTime, setAvailableTotime] = useState('')
	const [blockResValue, setBlockRes] = useState(false)


	const [checked1, setChecked1] = useState(true);

	const [editPrice, setEditPrice] = useState(true);
	const [price, setPrice] = useState('');
	const [price1, setPrice1] = useState('');
	const [showNf, setShowNf] = useState(false);
	const [venuTimings, setVenuTimings] = useState('');
	const [blockR, setBlockR] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [displayPosition, setDisplayPosition] = useState(false);
	const [position, setPosition] = useState('center');
	const { restaurantStore } = useStore();
	const [holidayList, setHolidayList] = useState();
	const [showHoliday, setShowHoliday] = useState(false);

	const [resData, setResData] = useState();

	const [checkboxValue, setValue] = useState(false)

	const [showTime, setShowTime] = useState(true);
	const [venueTime, setVenue] = useState(true);

	const [listSdate, setSdt] = useState(false);
	console.log("testing date",listSdate)
	const [listEdate, setEdt] = useState(false);


	console.log("restaurant settings data", resData);
	console.log("from time", fTime);
	console.log("to time", tTime);
	console.log("checkbox value", checkboxValue);
	console.log("date comparision", moment(toDt, "MM/DD/YYYY").isAfter(moment(fromDt, "MM/DD/YYYY")))
	const oncheckvalue = () => {
		console.log("function check", checkboxValue);
		setValue(!checkboxValue)
		setShowTime(!showTime)



	}

	const history = useHistory();

	const Goback = () => {
		history.goBack()
	}

	const isChecked = () => {
		setBlockR(!blockR);
		restaurantStore.blockReservation()
			.then(([success, message]) => {
				if (success) {
					console.log("reservation blocked");
					setDialog3(false)

				} else {
					// setErrorMsg(message),
					console.log("could not block reservations", message)
				}
			})
		onHide();
	}

	const unMark = (id: any) => {
		restaurantStore.deleteHoliday(id)
			.then(([success, message]) => {
				if (success) {
					console.log("unmarked succesfully");
					toast.success("unmarked successfully")
					getHolidays();


				} else {
					// setErrorMsg(message),
					// setTtype("error");
					// setMsg(message);
					toast.error(message);
					console.log("not unmarked", message)
				}
			})
	}

	const getRestData = () => {
		restaurantStore.getRestaurantSettings(sessionStorage.getItem('RestaurantId'))
			.then(([success, data]) => {
				if (success) {
					setResData(data.restaurant_settings)
					console.log("restaurant settings", data);

					let Ftime = moment(data.restaurant_settings.venue_start_time, "HH:mm:ss").format("hh:mm a");
					let Ttime = moment(data.restaurant_settings.venue_close_time, "HH:mm:ss").format("hh:mm a")
					setAvailableFromtime(Ftime.toString());
					setAvailableTotime(Ttime.toString())
					setBlockRes(data.restaurant_settings.block_reservations)
					// setShow(false);
				} else {

					console.log("error")
					// setErrorMsg(data)
				}
			})
	}

	const saveTimings = () => {
		console.log("save method called");
		console.log("from time", fTime);
		console.log("to time", tTime);
		// 	console.log( moment(tTime, "hh:mm:ss").isAfter(moment(fTime, "hh:mm:ss")));
		// 	setVenue(moment(tTime, "hh:mm:ss").isAfter(moment(fTime, "hh:mm:ss")));
		// console.log("venue check",venueTime)

		if (moment(tTime, "hh:mm a").isAfter(moment(fTime, "hh:mm a")) === true) {
			setDialog2(true);


		}
		else {
			// setTtype("error");
			// setMsg("End time should be greater than Start time")
			toast.error("End time should be greater than Start time")
			setEditPrice(false)

		}

	}


	const restSettingsUpate = () => {
		let settings = {
			name: resData?.restaurant_name,
			logo_url: resData?.restaurant_logo,
			address: {
				address1: resData?.address.address1,
				address2: resData?.address.address2,
				land_mark: resData?.address.land_mark,
				zip_code: resData?.address.zip_code,
				latitude: resData?.address.latitude,
				longitude: resData?.address.longitude,
				city: resData?.address.city,
				state: resData?.address.state,
				country: resData?.address.country
			},
			email: resData?.restaurant_email,
			status: resData?.status,
			party_size_limit: resData?.party_size_limit,
			// per_head_booking_deposit: parseInt(amountRes.replace("£ ","")),
			is_otp_enabled: resData?.is_otp_enabled,
			domain: "string",
			venue_start_time: moment(fTime,'hh:mm a').format("HH:mm:ss"),
			venue_close_time: moment(tTime,'hh:mm a').format("HH:mm:ss"),
			deposit_amount: resData?.deposit_amount,
			booking_period: resData?.booking_period,
			deposit_min_guest: resData?.deposit_min_guest,
			rest_id: resData?.rest_id,
			no_show_period: resData?.no_show_period,
			duration_btn_bookings: resData?.duration_btn_bookings,
			TWILIO_ACCOUNT_SID: resData?.TWILIO_ACCOUNT_SID,
            TWILIO_AUTHTOKEN: resData?.TWILIO_AUTHTOKEN,
            TWILIO_FROM_NO: resData?.TWILIO_FROM_NO,
            STRIPE_API_KEY: resData?.STRIPE_API_KEY,
            STRIPE_SECRET_KEY: resData?.STRIPE_SECRET_KEY,
			timezone:resData?.timezone
		}
		console.log(settings);
		restaurantStore.updateSettings(settings)
			.then(([success, message]) => {
				if (success) {
					// setData(data.restaurant_settings);
					// setTtype("success");
					// setMsg("saved venue timings succesfully");
					toast.success("saved venue timings succesfully")
					getRestData();
				} else {
					// setMsg(message.msg);
					// setTtype("error");
					setAvailableFromtime(moment(resData.venue_start_time,"hh:mm:ss").local().format('hh:mm a'))
					setAvailableTotime(moment(resData.venue_close_time,"hh:mm:ss").local().format('hh:mm a'))
					toast.error(message)

				}
			})
	}

	useEffect(() => {
		getRestData();
		// restSettingsUpate()
	}, [])
	// create holiday

	const createH = (values: any) => {

		var fromd: any = moment(values.fromdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
		var totd: any = moment(values.todate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
		var fromtt: any = moment(values.fromtime, 'hh:mm a').format('HH:mm:ss');
		var tott: any = moment(values.totime, 'hh:mm a').format('HH:mm:ss');
		

		console.log("holiday data", fromd,totd,checkboxValue,fromtt,tott);
		const hday = {
            rest_id: sessionStorage.getItem('RestaurantId'),
			title: values.title,
			start_date: fromd,
			end_date: totd,
			is_closed_all_day:checkboxValue,
			closed_from:checkboxValue?"10:00:00":fromtt,
			closed_to:checkboxValue?"21:00:00":tott
		}

		restaurantStore.createHoliday(hday)
			.then(([success, message]: any) => {
				if (success) {
					setErrorMsg(null)
					console.log("holiday output", message);
					// setTtype("success");
					// setMsg(message.status);
					toast.success("Holiday Created Successfully")

					getHolidays();
					setDialog(false);

					setTitl("");
					setFromDate("");
					setToDate("");
					setFromTime("");
					setToTime("");
					setTimeout(() => {
						// history.push('/manageWaiter')
					}, 4000);
					// return data

				} else {
					// setErrorMsg(message),
					// setMsg(message.msg);
					// setTtype("error");
					toast.error(message)
					console.log("not working", message)
				}
			})
	}





	// const timeSlots = getTimeOptions('00:00', '23:xxxx45');


	const [dialog3, setDialog3] = useState(false);

	const [dialog, setDialog] = useState(false);
	const [dialog2, setDialog2] = useState(false);
	const [date2, setDate2] = useState();
	const [date3, setDate3] = useState();
	const [date9, setDate9] = useState();
	const [date7, setDate7] = useState();
	const [title, setTitle] = useState();

	const onclick = () => {
		setDialog(true);
	}
	const onHide = () => {
		setDialog(false);
		setDialog2(false);
		setDialog3(false);


	}

	

	// useEffect(() => {

	// 	if (checked1 === true) {
	// 		setDialog3(true)
	// 		console.log("true");


	// 	}
	// 	else  {

	// 		console.log("false")
	// 		// setDialog3(false)

	// 		restaurantStore.unblockReservation()
	// 			.then(([success, message]) => {
	// 				if (success) {
	// 					console.log("reservation unblocked")
	// 				} else {
	// 					// setErrorMsg(message),
	// 					console.log("could not unblock reservations", message)
	// 				}
	// 			})
	// 	}
	// }, [checked1])

	const markH = () => {
		setDialog(false);
		setShowNf(true);
	}
	const closeEdit = () => {
		setEditPrice(true)
	}
	const [timings, setTimings] = useState({ fromtime: '', totime: '' });
	const update = (values: any) => {
		setTimings(values);
	}

	const onCancel=()=>{
		setAvailableFromtime(moment(resData.venue_start_time,"hh:mm:ss").local().format('hh:mm a'))
			setAvailableTotime(moment(resData.venue_close_time,"hh:mm:ss").local().format('hh:mm a'))
	}

	const getHolidays = () => {

		restaurantStore.getHolidayList()
			.then(([success, data]: any) => {
				if (success) {
					console.log("Holiday output", data);
					setHolidayList(data);

			
					console.log("holiday data : ", data)
					setShowHoliday(true)
				} else {
					console.log("not working", data)
				}


			})
	}



	useEffect(() => {


		getHolidays()


		if (showHoliday) {
			if (holidayList?.length > 0) {
				setShowNf(true);
			}
			else {
				setShowNf(false)
			}


		}


		if (venuTimings === 'true') {

			restSettingsUpate()
			// setPrice(timings.fromtime);
			// setPrice1(timings.totime);
			setEditPrice(true);
			setDialog2(false);
			setVenuTimings('');
		}
		else if (venuTimings === 'false') {
			setPrice('');
			setPrice1('');
			setDialog2(false);
			setVenuTimings('');
			setAvailableFromtime(moment(resData.venue_start_time,"hh:mm:ss").local().format('hh:mm a'))
			setAvailableTotime(moment(resData.venue_close_time,"hh:mm:ss").local().format('hh:mm a'))
			setEditPrice(true);
		}

	}, [venuTimings, timings, showHoliday])

	const [shiftList, setShiftList] = useState([])
	const ShiftsData = async () => {
		await restaurantStore.getShifts()
			.then(([success, message]: any) => {
				if (success) {
					console.log("Shift List", message)
					toast.success(message)
					// setShiftList(message);
					const shifts = message.filter((shift: any) => shift.is_deleted === false)
					setShiftList(shifts)
				} else {
					toast.error(message.msg)
				}
			})
	}
	useEffect(() => {
		ShiftsData();
	}, []);
	return <Fragment>
		<Toaster type={Ttype} text={msg} />
		<div id="content" className="position-relative settings ">
			<div className="breadcrum">
				<h2 onClick={Goback} className="breadcrumText activeBreadcrum">settings</h2 >
				<h2 className="breadcrumText"> &gt; </h2>
				<h2 className="breadcrumText">Time &amp; Availability</h2>
			</div>
			<hr className="hr" />
			<Formik
				initialValues={{ fromtime: fTime, totime: tTime }}
				validationSchema={yup.object({

					// fromtime: yup.string()
					// 	.required('Required'),
					// totime: yup.string()
					// 	.required('Required').test("is-greater", "is less then from time", function (value) {
					// 		const { fromtime } = this.parent;
					// 		return moment(value, "hh:mm:ss").isSameOrAfter(moment(fromtime, "hh:mm:ss"));
					// 	}),
				})}
				onSubmit={
					(values) => {
						console.log("on setvenue settings", values)
						update(values);

					}
				}
			>
				{({ errors, touched }) => (
					<Form>
						<div className="inner-content">
							<div className="section-title text-primary text-capitalize">
								<span className="text-black">set venue timings [12 hrs] &nbsp;&nbsp;</span>
								<span className="edit_section edit price-edit">
									{/* <img src="assets/images/edit-text.svg" alt="Edit Icon" style={{ marginLeft: '20px' }} /> */}

									<span className="price-edit">
										{editPrice ? <span onClick={() => setEditPrice(false)}><img className="price-editIcon" src="./assets/images/edit2.png" alt="" />&nbsp;EDIT</span> : <span><span className="pricesave"><button type="submit" onClick={saveTimings} className="price-edit"><img className="price-editIcon" src="./assets/images/save.svg" alt="" />&nbsp;<span className="green">SAVE</span></button></span> <span><button type="reset" onClick={closeEdit} className="price-edit"><i style={{ fontSize: '16px' }} className="far fa-times-circle text-orange"></i>
											<span className="text-capitalize text-orange" onClick={onCancel} >Cancel</span></button></span></span>}</span>
								</span>

							</div>


							<div className="content-section-spacing vto">
								<div className="venue-timing output d-flex flex-no-wrap  justify-content-between">
									<div className="tm-input from">
										<label className="d-block">From (hh:mm) <ErrorMessage name="fromtime" component={FormErrorMsg} />
										</label>
										<Field readOnlyInput as={InputMask} name="fromtime" style={{ width: '100%', marginTop: '5px' }} disabled={editPrice} mask="99:99 aa"
											value={fTime}

											onChange={(e: any) => setAvailableFromtime(e.value)}
											className={(errors.fromtime && touched.fromtime ? ' is-invalid' : 'priceinputtext')}
										// className='priceinputtext'
										/>
									</div>
									<div className="tm-input to">
										<label className="d-block">To (hh:mm)<ErrorMessage name='totime' component={FormErrorMsg} />
										</label>

										<Field readOnlyInput as={InputMask} style={{ width: '100%', marginTop: '5px' }} name="totime" disabled={editPrice} mask="99:99 aa"
											onChange={(e: any) => setAvailableTotime(e.value)} value={tTime}

											className={(errors.totime && touched.totime ? ' is-invalid' : 'priceinputtext')}
										/>

										{/* <Field as={InputMask} autoComplete="off" name="From" className={(errors.From && touched.From ? ' is-invalid' : ' ')} id="From" mask="99:99 aa" placeholder="ex: 01:00 AM" /> */}

									</div>
								</div>
							</div>


							<div className="section-title text-primary text-capitalize">
								<span className="text-black">Shifts</span>
							</div>
							<div className="manageShift">
								<div className="shifts">
									{shiftList.map((shift: any) => (
										<h4>{shift.name}</h4>
									))}
								</div>
								<Link to="/manage-shifts"><p>Manage Shifts</p></Link>
							</div>

							<div className="section-title text-primary text-capitalize">
								<span className="text-black">calendar settings <p style={{ float: 'right' }}><a href="#" className="text-primary" onClick={onclick}><i className="fas fa-calendar-day">&nbsp;</i>  Mark holiday</a></p></span>
							</div>
							<div className="content-section-spacing d-flex flex-no-wrap align-items-stretch justify-content-between">
								<div id="datepicker"></div>
								{/* <div className="calender-box  holidays-list holidays-listed custom-scrollbar ">
									<Calendar value={new Date(date12)} onChange={(e) => { setDate12((e.target.value).toString()); console.log(e.target.value) }} readOnlyInput name="selectedDay" inline showWeek />
								</div> */}


								{showHoliday ?
									<div hidden={!showNf} className="holidays-list holidays-listed custom-scrollbar " style={{ overflowY: 'scroll' }}>
										{holidayList.filter((deleted: any) => deleted.is_deleted === false).map((item: any) => (

											<li key={item.id}>

												<div className="holidays-row bg1-white d-flex flex-no-wrap align-items-stretch">
													<div className="holidays-columns text-left">
														<div className="holiday-title text-black weight-semi">{item.title}</div>
														<div className="holiday-date text text-gray">{moment(moment(item.start_date).toDate()).local().format("yyyy-MM-DD")} to {moment(item.end_date).local().format("yyyy-MM-DD")}</div>
														<a href="javascript:void(0);" onClick={() => unMark(item.id)} className="holiday-mark text text-orange">Unmark</a>
													</div>
													<div className="holidays-columns text-right  justify-content-end">
														{item.is_closed_all_day ?
															<div className="allday">All Day</div> : null}

														{item.is_closed_all_day ? null :
															<div style={{textTransform:"uppercase"}} className="holiday-time text">{moment(item.closed_from,"hh:mm:ss").local().format('hh:mm A')} to {moment(item.closed_to,"HH:mm:ss").local().format('hh:mm a')}</div>}
													</div>
												</div>
											</li>
										))
										}
										{/* <div className="holidays-row bg-white d-flex flex-no-wrap align-items-stretch">
										<div className="holidays-columns text-left">
											<div className="holiday-title text-black weight-semi">The Christmas Day</div>
											<div className="holiday-date text text-gray">25th December 2019</div>
											<a href="javascript:void(0);" className="holiday-mark text text-orange">Unmark</a>
										</div>
										<div className="holidays-columns text-right d-flex flex-no-wrap flex-column justify-content-end">
											<div className="holiday-shift text text-italic">All Day</div>
											<div className="holiday-time text text-italic">09:00 AM to 06:00 PM</div>
										</div>
									</div> */}
									</div>
									: null}

							</div>

							{/* <div className="section-title text-primary text-capitalize">
								<span className="text-black">restaurant status</span>
							</div> 
							<div className="resturant-status content-section-spacing">
								<div className="custom-switch round">
									<span className="title text-black text-capitalize">active for services</span>
									<div className="custom-toggle-btn d-inline-block pointer">
										<label htmlFor="checkbox2" className="text-white text-center d-inline-block position-relative bg-green pointer">
										</label>
										<input type="checkbox" id="checkbox2" className="d-none" />
									</div>
								</div>
								<div className="note">Note : By switching to inactive status, the admin and reservation related services will be disabled.</div>
							</div> */}
						</div>
					</Form>
				)
				}
			</Formik>
			<Dialog visible={dialog} position="center" style={{ width: 'auto', height: '65vh' }} onHide={() => onHide()}>
				<Formik
					initialValues={{ title: "", fromdate: "", closeallday: "", todate: "", fromtime: "", totime: "" }}
					validationSchema={yup.object({

						title: yup.string()
							.required('Required'),
						fromdate: yup.string().nullable()
							.required('Required'),
						todate: yup.string().nullable()
							.required('Required').test("is-greater", "is less then from date",
								function (value) {
									if (value) {
										console.log("value", value);
										const { fromdate } = this.parent;
										console.log("fromdate", fromdate);

										let fmdateObj = moment(fromdate)
										let todateObj = moment(value)

										return todateObj >= fmdateObj

									}
									return false
								}),
						// fromtime: yup.string().nullable()
						// 	.required('Required'),
						// totime: yup.string().nullable()
						// 	.required('Required').test("is-greater", "is less then from time", function (value) {
						// 		const { fromtime } = this.parent;
						// 		return moment(value, "HH:mm a").isSameOrAfter(moment(fromtime, "HH:mm a"));
						// 	}),
					})}
					onSubmit={values => {
						console.log(values);
						createH(values);


						// ; setTitl(values.title); setFromDate(values.fromdate); setToDate(values.todate); setFromTime(values.totime); setToTime(values.fromtime);
					}}
				>
					{({ errors, touched }) => (<Form>

						<div className="markHolidays ">
							<div className="tm-input">

								<div className="mrh-txt">Mark Restaurant Holiday <i onClick={onHide} className="pi pi-times close"></i>
								</div>


								<hr className="hr" />

								<div className="">
									<label htmlFor="title" className="popup-title">Title <ErrorMessage name='title' component={FormErrorMsg} />
									</label><br />

									<Field as={InputText} style={{ marginTop: '7px', marginBottom: '20px' }} maxLength='20' autoComplete="off" name="title" type="text"
										className={(errors.title && touched.title ? ' is-invalid' : 'mark-field')}
									/>
								</div>

								{/* <div className="select-date">Select Date</div> */}
								<div className="calender-input">
									<div className="fromDate">
										<label htmlFor="fromdate" className="cal-lbl">From Date <ErrorMessage name='fromdate' component={FormErrorMsg} />
										</label>
										{/* <input type="text" id="from" name="from" className="form-control" placeholder="MM/DD/YYYY" />
											<i className="fas fa-calendar-alt fa-2x position-absolute"></i> */}

										<Field as={Calendar} readOnlyInput id="icon" name="fromdate" style={{ marginBottom: '20px' }}
											className={(errors.fromdate && touched.fromdate ? ' is-invalid' : '')}
											showIcon />



									</div>
									<div className="toDate">
										<label htmlFor="todate" className="cal-lbl">To date <ErrorMessage name='todate'
											component={FormErrorMsg} />
										</label>

										{/* <input type="text" id="to" name="to" className="form-control" placeholder="MM/DD/YYYY" />
											<i className="fas fa-calendar-alt fa-2x position-absolute"></i> */}

										<Field as={Calendar} readOnlyInput id="icon" name="todate" style={{ marginBottom: '20px' }}
											className={(errors.todate && touched.todate ? ' is-invalid' : '')}
											showIcon />

									</div>
								</div>

								<div className="closed-all-day">
									<span className="">
										<input type="checkbox" onChange={oncheckvalue} checked={checkboxValue} id="closed-all-day" name="closeallday" className="" />
										<i className=""></i>
									</span>
									&nbsp;&nbsp;	Closed All Day
								</div>

								{showTime ?
									<div>

										{/* <div className="closing-time">Closing Time</div> */}
										<div className="time-field" >
											<div className="clndr">
												<label htmlFor="fromtime" className="cal-lbl">From Time (hh:mm)<ErrorMessage name='fromtime' component={FormErrorMsg} />
												</label>

												{/* <input type="text" id="closing-from" name="closing-from" className="form-control" placeholder="09:00 AM" /> */}

												<Field as={InputMask} readOnlyInput name="fromtime" style={{ fontSize: "16px", marginBottom: '20px' }}
													className={(errors.fromtime && touched.fromtime ? ' is-invalid' : '')}
													mask="99:99 aa" />

											</div>
											<div className="clndr">
												<label htmlFor="totime" className="cal-lbl"  >To Time<ErrorMessage name='totime' component={FormErrorMsg} />
												</label>
												{/* <input type="text" id="closing-to" name="closing-to" className="form-control" placeholder="08:00 PM" /> */}

												<Field as={InputMask} readOnlyInput  name="totime" style={{ fontSize: "16px", marginBottom: '20px' }}
													className={(errors.totime && touched.totime ? ' is-invalid' : '')}
													mask="99:99 aa" />


											</div>
										</div>
									</div>
									: null}
								<div className="clndr-btn" >
									<div className="cncl">
										<button style={{ backgroundColor: 'white !important' }} onClick={onHide} type="button" className="" id="cancel" name="cancel">CANCEL</button>
									</div>
									<div className="hldy">
										<button type="submit" className="" id="holiday" name="holiday">MARK HOLIDAY</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
					)}
				</Formik>
			</Dialog>

			<Dialog visible={dialog2} style={{ width: '358px' }} onHide={() => onHide()}>
				<h3 className="logoutTtl">Are you sure, you want to set venue Timings?</h3>
				<br />
				<span><button style={{ width: "70px", marginRight: "10px" }} onClick={() => setVenuTimings('false')} className="outline">No</button></span>

				<span><button style={{ width: "70px", height: "35px" }} onClick={() => setVenuTimings('true')} className="filled">Yes</button></span>
				<br />

			</Dialog>

		</div>

	</Fragment>
}


export default ProfileSettings
