import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import App from "./App";
import Login from './pages/Login'
import AuthRoute from './AuthRoute'
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import AccessDenied from "./pages/AccessDenied";
import ForgotPassword from './pages/forgotPassword';
import LogoutConf from './pages/logoutConf';

export const scrollToRef = (ref: any) => {
	//console.log(ref.current.offsetTop)
	window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop - 100 }) // 70px app topbar + 30 space
}

function AppWrapper() {
	return (
		<Switch>
			<Route path="/login">
				<Login />
			</Route>
			<Route path="/error">
				<Error />
			</Route>
			<Route path="/404">
				<NotFound />
			</Route>
			<Route path="/accessdenied">
				<AccessDenied />
			</Route>
			<Route path="/forgot-password">
				<ForgotPassword />
			</Route>
			<Route path="/logoutConformation">
				<LogoutConf />
			</Route>
			<AuthRoute>
				<App />
			</AuthRoute>
		</Switch>
	)
}

export default AppWrapper;