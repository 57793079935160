import React, { Component } from 'react';
import { Button } from "primereact/button";
import { Card } from 'primereact/card'
import { Link } from 'react-router-dom';

export default class NotFound extends Component {

	render() {
		return <div className='page-container'>
			<div className="p-grid p-justify-center p-align-center">
				<div className="p-col-10">
					<Card title='Page Not Found' >
						<p>Requested resource is not available.</p>
						<Link to='/' >
							<Button label="Home" />
						</Link>
					</Card>
				</div>
			</div>
		</div>
	}
}