import React, { Fragment, useEffect, useState } from 'react';
import './toaster.css'
const Toaster= (props:any) => {
    const [toast, setToast] = useState(false)
    const [show, setShow] = useState(false);
    // var audio = new Audio(Success);
    // audio.play();
    useEffect(()=>{
        if(props.type){
            setShow(true);
            setTimeout(() => {
                setToast(true);
            }, 3000);
            setTimeout(() => {
                setToast(false);
                setShow(false);
            }, 4000);
        }
    },[props.type])
    
    
    return <Fragment>
        {show ?
            <div style={{ right: toast ? "-400px" : "20px" }} className="ToastCont">
                <div className="Toaster">
                    <p className={props.type === "success" ? "Head success" : props.type === "error" ? "Head Error" : " "}>{props.type}</p>
                    <p className="Message">{props.text}</p>
                </div>
                <div className={props.type === "success" ? "Icon successBg" : props.type === "error" ? "Icon ErrorBg" : " "}>
                    {props.type === "success" ?
                        <i className="pi pi-check"></i> : props.type === "error" ? <i className="pi pi-times"></i> : null
                    }
                </div>
            </div>
            : null
        }
    </Fragment>
}

export default Toaster