import React, { Fragment} from 'react';
import "./AppTopbar.css";
import { Link } from 'react-router-dom';


const AppTopbar = () => {
    return <Fragment>
        <div className="header">
        <img className='headlg' src="./assets/logo/purple bg.svg" alt="brand-logo" />
            <div className="rightMenu">
                <Link to="/adminprofile">
                    <img src='./assets/images/admin3.png' alt="user-Logo" className="avatar" />
                </Link>
            </div>
        </div>


    </Fragment>



}

export default AppTopbar;